import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import LadakhImg from "../../assets/Images/LadkDest.svg";
import "./LadakhDestinations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faHotel,
  faCar,
  faPersonHiking,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

const LadakhDestinations = () => {
  const [destinations, setDestinations] = useState([]);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllDestination`
        );
        const data = await response.json();
        if (data.status === "true") {
          setDestinations(data.data);
        } else {
          console.error("Error fetching data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Function to chunk destinations into groups of 4
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  return (
    <>
      <div>
        <div className="position-relative">
          <Nav />
          <img
            src={LadakhImg}
            alt="ladakhDestinations"
            className="w-100 img-fluid"
          />
          <div className="overlay-text">
            <h3 className="text-center">
              <b>Our Destinations Ladakh</b>
            </h3>
          </div>
        </div>

        {/* Render cards in rows */}
        {chunkArray(destinations, 4).map((row, rowIndex) => (
          <div className="container">
            <div key={rowIndex} className="row ">
              {row.map((destination) => (
                <div
                  key={destination.id}
                  className=" col-lg-3 my-4 pe-3 h-100"
                  style={{ borderRadius: "19px" }}
                >
                  <div className="p-3 card border">
                    <img
                      src={destination.Image}
                      className="card-img-top rounded border"
                      alt={destination.Name}
                      style={{
                        borderRadius: "8.86px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="card-body" style={{ gap: "26.57px" }}>
                      <h6
                        className="card-title d-flex align-items-center justify-content-between"
                        style={{ fontSize: "18px" }}
                      >
                        {destination.Name}{" "}
                        <span>
                          <FontAwesomeIcon style={{color:"#ffd049"}} icon={faStar} />
                          <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                            4.9
                          </span>
                        </span>
                      </h6>
                      {/* <p style={{ opacity: "0.5", fontSize: "14px" }}>
                        {destination.Day} Days & {destination.Night} Nights
                      </p> */}
                    </div>
                    <div className="row">
                      <div
                        className="col-4 d-flex flex-column align-items-center"
                        style={{ opacity: "0.5" }}
                      >
                        <FontAwesomeIcon
                          icon={faHotel}
                          style={{ color: "#000000" }}
                        />
                        <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                          {" "}
                          {destination.Activities[0]} Hotel
                        </span>
                      </div>
                      <div
                        className="col-4 d-flex flex-column align-items-center"
                        style={{ opacity: "0.5" }}
                      >
                        <FontAwesomeIcon icon={faCar} />
                        <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                          {" "}
                          {destination.Activities[1]} Transfers
                        </span>
                      </div>
                      <div
                        className="col-4 d-flex flex-column align-items-center"
                        style={{ opacity: "0.5" }}
                      >
                        <FontAwesomeIcon icon={faPersonHiking} />
                        <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                          {" "}
                          {destination.Activities[2]} Activities
                        </span>
                      </div>
                    </div>
                    <div className="card-body pb-0 px-0">
                      <div
                        style={{ fontSize: "13px" }}
                        dangerouslySetInnerHTML={{
                          __html: destination.Details,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <Footer />
      </div>
    </>
  );
};

export default LadakhDestinations;
