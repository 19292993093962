import React, { useState } from 'react';
import Nav from '../HomePage/Nav';
import faq from "../../assets/Images/faqs.jpeg";
import Footer from '../HomePage/Footer';

const Faq = () => {
  const [accordionState, setAccordionState] = useState({
    accordion1: false,
    accordion2: false,
    accordion3: false,
    accordion4: false,
    accordion5: false,
  });

  const toggleAccordion = (accordionKey) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  return (
    <>
      <Nav />
      <div
        className="container-fluid p-0 position-relative d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${faq})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "95vh",
        }}
      >
        <div className="accordion" style={{ backgroundColor: "transparent", maxWidth: "600px" }}>
          <div className="accordion-item" style={{ marginTop: "20px" }}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                aria-expanded={accordionState.accordion1}
                aria-controls="collapseOne"
                onClick={() => toggleAccordion("accordion1")}
              >
                Accordion Item #1
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${accordionState.accordion1 ? "show" : ""}`}
              id="collapseOne"
            >
              <div className="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{ marginTop: "20px" }}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                aria-expanded={accordionState.accordion2}
                aria-controls="collapseTwo"
                onClick={() => toggleAccordion("accordion2")}
              >
                Accordion Item #2
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${accordionState.accordion2 ? "show" : ""}`}
              id="collapseTwo"
            >
              <div className="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{ marginTop: "20px" }}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                aria-expanded={accordionState.accordion3}
                aria-controls="collapseThree"
                onClick={() => toggleAccordion("accordion3")}
              >
                Accordion Item #3
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${accordionState.accordion3 ? "show" : ""}`}
              id="collapseThree"
            >
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{ marginTop: "20px" }}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                aria-expanded={accordionState.accordion4}
                aria-controls="collapseFour"
                onClick={() => toggleAccordion("accordion4")}
              >
                Accordion Item #4
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${accordionState.accordion4 ? "show" : ""}`}
              id="collapseFour"
            >
              <div className="accordion-body">
                <strong>This is the fourth item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{ marginTop: "20px" }}>
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                aria-expanded={accordionState.accordion5}
                aria-controls="collapseFive"
                onClick={() => toggleAccordion("accordion5")}
              >
                Accordion Item #5
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${accordionState.accordion5 ? "show" : ""}`}
              id="collapseFive"
            >
              <div className="accordion-body">
                <strong>This is the fifth item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
