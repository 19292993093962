import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './HotSelling.css';
const HotSelling = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const imageRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}HotSelling`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log("HotSelling Data - ", data.data);
        setImages(data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
          } else {
            entry.target.classList.remove('active');
          }
        });
      },
      { threshold: 0.1 }
    );

    imageRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      imageRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [images]);

  const handleImageClick = (id, name) => {
    sessionStorage.setItem('tripPackageId', id);
    const link = id === 1 ? '/Ladakh-Stay' : `/TripPackages/${name}`;
    navigate(link);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <section className="container-fluid pb-5">
      <h3 className="text-center fw-bold mb-4 text-primary">HOT SELLING</h3>
      <div className="row justify-content-center mt-3 gap-4 mx-3">
        {images.map((image, index) => (
          <div key={image.Id} className="col-md-3 text-center reveal" ref={(el) => (imageRefs.current[index] = el)}>
            <div
              onClick={() => handleImageClick(image.Id, image.Name)}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={image.Image}
                className="img-fluid border"
                alt={image.Name}
                style={{ borderRadius: '11.24px', maxHeight: '233px', objectFit: 'cover' }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HotSelling;
