import React from "react";
import p1 from "../../assets/Images/P1.png";
import p2 from "../../assets/Images/P2.png";
import lad from "../../assets/Images/11.png";
import snow from "../../assets/Images/12.png";

const Glimpse = () => {
  return (
    <div className="pt-5 text-center">
      <h3 className="text-primary">
        <b>A GLIMPSE OF OUR TOUR</b>
      </h3>
      <div className="container mt-5 d-flex justify-content-center">
        <div className="row">
          <div className="col-lg-5 pe-0 pe-md-4 ">
            <img
              src={p1}
              alt="p1"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                objectFit: "cover"
              }}
            />
          </div>

          <div className="col-lg-3 pe-0 pe-md-4  mt-1 mt-md-0">
            <img
              src={p2}
              alt="p2"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                objectFit: "cover"
              }}
            />
          </div>

          <div className="col-lg-4 mt-1 mt-md-0">
            <img
              src={lad}
              alt="p3"
              style={{
                width: "100%",
                height: "222.67px",
                borderRadius: "5px",
                objectFit:"cover"
              }}
            />
            <img
              src={snow}
              alt="p4"
              style={{
                marginTop: "0.5rem",
                width: "100%",
                height: "179.67px",
                borderRadius: "5px",
                marginbottom: "16px",
                objectFit:"cover"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glimpse;
