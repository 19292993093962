import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faLayerGroup,
  faUsers,
  faUserShield,
  faHeadset,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons"; // Import necessary icon

const WhyChooseUs = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);
  const handleAutoplay = () => {
    setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.next();
      }
    }, 5000);
  };

  // Call autoplay function on component mount
  React.useEffect(() => {
    handleAutoplay();
  }, []);

  return (
    <>
      <div className="py-5 mt-5" style={{ background: "#F0F7F9" }}>
        <div className="container">
          <h3 className="text-center mb-5 text-primary">
            <b>WHY CHOOSE US</b>
          </h3>
          <Carousel
            responsive={responsive}
            containerClass="carousel-container" // Added class for styling
            itemClass="carousel-item-padding" // Added class for styling
            ref={carouselRef}
            infinite
          >
            {/* Each slide */}
            <div>
              <div
                className="card mb-3 mx-4 custom-card1 h-100"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="card-body text-center p-4">
                  <h5 className="card-title">
                    {/* <FontAwesomeIcon icon="fa-solid fa-book" /> */}
                    <FontAwesomeIcon
                      className="mt-4"
                      style={{ fontSize: "60px" }}
                      icon={faBook}
                    />
                  </h5>
                  <h4 className="fw-bold mb-3 mt-5">Local Knowledge</h4>
                  <p className="card-text pb-4">
                    Ladakh is a unique place; we know it well because we live
                    here. We understand the best spots to visit, the perfect
                    times to explore, and the hidden gems most people miss. With
                    us, you'll get a truly local experience.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="card mb-3 mx-4 custom-card1 h-100"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="card-body text-center p-4">
                  <h5 className="card-title">
                    <FontAwesomeIcon
                      className="mt-4"
                      style={{ fontSize: "60px" }}
                      icon={faLayerGroup}
                    />
                  </h5>
                  <h4 className="fw-bold mb-3 mt-5">Stress-Free Planning</h4>
                  <p className="card-text pb-4">
                    Planning a trip can be a lot of work. That's where we come
                    in. We care for everything, from booking your accommodation
                    to organizing your transportation. You can relax knowing
                    that we've got it all covered.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="card mb-3 mx-4 custom-card1 h-100"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="card-body text-center p-4">
                <h5 className="card-title">
                  <FontAwesomeIcon
                    style={{ fontSize: "60px" }}
                    icon={faUserShield}
                    className="mt-4"
                  />
                </h5>
                <h4 className="fw-bold mb-3 mt-5">Safety First</h4>
                <p className="card-text pb-4">
                Ladakh's landscape can be challenging, with high altitudes and remote areas. Our team is experienced and trained to keep you safe. We have safety measures in place and backup plans so you can enjoy your trip without worry.
                </p>
              </div>
            </div>

            <div
              className="card mb-3 mx-4 custom-card1 h-100"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="card-body text-center p-4">
                <h5 className="card-title">
                  <FontAwesomeIcon
                    style={{ fontSize: "60px" }}
                    icon={faHeadset}
                    className="mt-4"
                  />
                </h5>
                <h4 className="fw-bold mb-3 mt-5">24/7 Support</h4>
                <p className="card-text pb-4">
                If you need anything during your trip, you can reach us anytime, day or night. We're always available to answer your questions or help with any issues. This way, you're never alone, even in a new place. </p>
              </div>
            </div>

            <div
              className="card mb-3 mx-4 custom-card1 h-100"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="card-body text-center p-4">
                <h5 className="card-title">
                  <FontAwesomeIcon
                    style={{ fontSize: "60px" }}
                    icon={faMoneyBill}
                    className="mt-4"
                  />
                </h5>
                <h4 className="fw-bold mb-3 mt-5">No Hidden Costs</h4>
                <p className="card-text pb-4">
                You don't want surprises when it comes to costs. We believe in transparent pricing, so you'll know exactly what you're paying for. There are no hidden fees, just straightforward pricing. </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
