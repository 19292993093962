

import React, { useEffect, useState } from "react";
import ProfileNav from "./ProfileNav";
import { NavLink } from "react-router-dom";
import "./Transactions.css";

const Transactions = () => {
  const [token, setToken] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch token from session storage
  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // Fetch transactions using the retrieved token
  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_API_URL}MyTransaction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "true") {
            setTransactions(data.data);
            setIsLoading(false);
          } else {
            throw new Error("Error fetching transactions: Status is not true");
          }
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          setIsLoading(false);
        });
    }
  }, [token]);

  const status = "Done";

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5 mx-5">
          <div className="col-lg-12">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                {transactions.length > 0 ? (
                  <div className="transaction-container">
                    <div className=" justify-content-between mt-5">
                      <h3 className="text-left">
                        <b>Transaction History </b>
                      </h3>
                      {/* <div className="d-flex justify-content-start mt-4">
                        <button
                          className="btn btn-primary mx-1"
                          style={{
                            borderRadius: "25px",
                          }}
                        >
                          All
                        </button>
                        <button
                          className="btn btn-primary mx-1"
                          style={{
                            borderRadius: "25px",
                            color: "#222222E5",
                          }}
                        >
                          Completed
                        </button>
                        <button
                          className="btn btn-primary mx-1"
                          style={{
                            borderRadius: "25px",
                          }}
                        >
                          Rejected
                        </button>
                      </div> */}
                    </div>
                    <table className="table mt-4 border rounded">
                      <thead>
                        <tr className="text-center">
                          <th>Order ID</th>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Transaction Id</th>
                          <th>Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr key={transaction.id} className="text-center">
                            <td>{transaction.OrderId}</td>
                            <td>{transaction.Date}</td>
                            <td>{transaction.Amount}</td>
                            <td>{transaction.TransactionId}</td>
                            <td>{transaction.Type}</td>
                            <td style={{ color:  "green" }}>
                              {transaction.Status}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "60vh" }}
                  >
                    <h3 style={{ color: "#717171" }}>No Transactions Found</h3>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
