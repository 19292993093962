import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faHotel,
  faCar,
  faPersonHiking,
} from "@fortawesome/free-solid-svg-icons";

import innsbrg from "../../assets/Images/innsbrg.png";
import faroe from "../../assets/Images/faroe.png";
import hall from "../../assets/Images/hall.png";

import "../../assets/Images/ladakh.jpg";
import "../HomePage/Home.css";
import CarImage from "../../assets/Images/Car.png";
import BikeImage from "../../assets/Images/bike.png";
import HotelImage from "../../assets/Images/hotel.png";
import Nubra from "../../assets/Images/nubra.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Service from "../HomePage/Services";
import WhyChooseUs from "./WhyChooseUs";
import ClientSays from "./ClientSays";
import Glimpse from "./Glimpse";
import TravLadhak from "./TravLadhak";
import Footer from "./Footer";
import Header from "./Header";
import HotSelling from "./HotSelling";
import Nav from "./Nav";
import Destinations from "./Destinations";

const Home = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3, // Adjusted to show at least 2.5 cards on tablet screens
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);
  const handleAutoplay = () => {
    setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.next();
      }
    }, 5000);
  };
  // Call autoplay function on component mount
  React.useEffect(() => {
    handleAutoplay();
  }, []);

  const handleImageClick = (imageType) => {
    // Handle the click event based on the image type
    console.log("image");
    console.log(`Clicked on ${imageType}`);
    // You can perform actions like fetching data from the backend, updating state, etc.
  };
  return (
    <div className="container-fluid p-0">
      <Nav />

      <Header />

      {/* <HotSelling
        carImage={CarImage}
        bikeImage={BikeImage}
        hotelImage={HotelImage}
      /> */}

      <HotSelling handleImageClick={handleImageClick} />

      <Destinations />

      {/* Services */}

      <div className="text-center py-5">
        <h3 className="mb-2 text-primary">
          <b>OUR SERVICES</b>
        </h3>
        <Service />
      </div>

      {/* Best Time To visit */}

      <div className="text-center mt-5 pb-5 container">
        <h3 className="text-primary">
          <b>BEST TIME TO VISIT</b>
        </h3>
        <p
          className="text-center mt-4"
          style={{ color: "#080B13", Font: "Poppins" }}
        >
          The best time to visit Leh Ladakh is during the summer, between May and Septemper. During this time, temperatures range from 15 to 30 degrees Celsius, making it ideal to explore the area. During the rest of the year, Ladakh is typically much colder, making summer the perfect season to visit.  During spring (March to May) and autumn (September to October), you will experience moderate weather and fewer crowds, providing you a distinct charm. Still, summer is the best-suited time as it gives you an ideal mix of ease of accessibility, weather, and cultural events with beautiful wild landscapes and prosperous cultural heritage.
        </p>
        {/* <button
          className="btn btn-primary mt-3"
          style={{
            borderRadius: "3px",
            color: "#202F59",
          }}
        >
          View More
        </button> */}
      </div>

      <WhyChooseUs />

      <ClientSays />

      <Glimpse />

      <TravLadhak />

      <Footer />

    </div>
  );
};

export default Home;
