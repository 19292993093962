import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Thankyou = () => {
    const redirectToBooking = () => {
        // Redirect logic here
        window.location.href = 'https://travellerchoiceladakh.com/profilePage1'; // Redirects to the booking page
      };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', padding: '20px', background: '#f0f0f0' }}>
      <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '80px', color: '#4caf50', marginBottom: '10px' }} />
      <h1 style={{ marginBottom: '10px' }}>
        Thank you for your payment!
      </h1>
      <p>
        Your transaction was successful. We appreciate your Travel.
      </p>
      <button onClick={redirectToBooking} style={{ padding: '10px 20px', marginTop: '20px', backgroundColor: '#4caf50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        Go to Bookings
      </button>
    
    </div>  
  );
};

export default Thankyou;
