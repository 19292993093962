import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./BlueNav.css"; // Import your CSS file with the blue background color class
import wlogo from "../../assets/Images/White_TCL_logo.png";

const BlueNav = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn") === "true"
  ); // Check if user is already logged in

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const closeNavbar = () => {
    setIsToggled(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
    toast.success("Successfully logged out", {
      position: "top-center",
      autoClose: 1000,
      onClose: () => {
        window.location.href = "/";
      },
    });
  };

  return (
    <nav className={`navbar navbar-expand-lg blue-nav p-0`}>
      <div className="container px-4">
        <div className="w-100 d-flex justify-content-between">
          <NavLink
            to="/"
            className="navbar-brand fw-bold text-white"
            onClick={closeNavbar}
          >
            <img src={wlogo} className="d-none d-md-block" width="150" alt="" />
            <img src={wlogo} className="d-block d-md-none" width="120" alt="" />
          </NavLink>
          <button
            className="navbar-toggler text-white"
            type="button"
            onClick={handleToggle}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>

        <div className={`collapse navbar-collapse ${isToggled ? "show" : ""}`}>
          <ul className="navbar-nav ml-auto gap-4">
            {/* Navigation links */}
            <li className="nav-item fw-semibold">
              <NavLink
                to="/"
                exact
                className="nav-link text-white"
                activeClassName="active"
                onClick={closeNavbar}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item fw-semibold text-white">
              <NavLink
                to="/AllPackages"
                exact
                className="nav-link text-white"
                activeClassName="active"
                onClick={closeNavbar}
              >
                Packages
              </NavLink>
            </li>
            <li className="nav-item fw-semibold text-white">
              <NavLink
                to="/plan-ladakh-trip"
                exact
                className="nav-link text-white"
                activeClassName="active"
                onClick={closeNavbar}
                style={{ width: "max-content"}}
              >
                Help me plan my trip
              </NavLink>
            </li>
            <li className="nav-item fw-semibold text-white">
              <NavLink
                to="/blogs"
                exact
                className="nav-link text-white"
                activeClassName="active"
                onClick={closeNavbar}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item fw-semibold text-white">
              <NavLink
                to="/ContactUs"
                exact
                className="nav-link text-white"
                activeClassName="active"
                onClick={closeNavbar}
                style={{ width: "max-content"}}
              >
                Contact Us
              </NavLink>
            </li>
            {!isLoggedIn ? ( // Render Sign In link if not logged in
              <li className="nav-item fw-semibold">
                <NavLink
                  to="/LogIn"
                  exact
                  className="nav-link text-white"
                  activeClassName="active"
                  onClick={closeNavbar}
                  style={{ width: "max-content"}}
                >
                  Sign In
                </NavLink>
              </li>
            ) : (
              // Render user icon dropdown if logged in
              <li className="nav-item dropdown">
                <button
                  className="nav-link dropdown-toggle text-white"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>

                {/* <div className="dropdown-menu" aria-labelledby="userDropdown">
                  <NavLink to="/profilepage1" className="dropdown-item">
                    Profile
                  </NavLink>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </div> */}

                <div
                  className={`dropdown-menu ${isToggled ? "show" : ""}`}
                  aria-labelledby="userDropdown"
                >
                  <NavLink
                    to="/profilepage1"
                    className="dropdown-item"
                    onClick={closeNavbar}
                  >
                    Profile
                  </NavLink>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default BlueNav;
