

import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import help from "../../assets/Images/Helptrip.svg";
import bg_help from "../../assets/Images/bg-help.svg";
import bikeimg from "../../assets/Images/bikeimg.svg";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { toast } from "react-toastify"; // Import the toast functionality

const HelpMePlan = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Country: "",
    PinCode: "",
    DOA: "",
    DOD: "",
    Travelers: "",
    HotelCategory: "",
    AboutIdea: "",
    AboutPlan: "",
    Budget: "",
  });
  const [submitted, setSubmitted] = useState(false); // State for submitted form

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    console.log("token--",token);
    const loggedIn = sessionStorage.getItem("isLoggedIn");
    if (token && loggedIn) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch( `${process.env.REACT_APP_API_URL}AddQoute`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ...formData,
  //         token: sessionStorage.getItem("token"),
  //       }),
  //     });
  //     const responseData = await response.json();
  //     setSubmitted(true); // Set submitted state to true
  //     toast.success("Details submitted successfully!", {
  //       position: "top-center",
  //       autoClose: 2000,
  //     });
  //     // Clear form fields
  //     setFormData({
  //       Name: "",
  //       Email: "",
  //       Country: "",
  //       PinCode: "",
  //       DOA: "",
  //       DOD: "",
  //       Travelers: "",
  //       HotelCategory: "",
  //       AboutIdea: "",
  //       AboutPlan: "",
  //       Budget: "",
  //     });
  //     // Handle success or failure response from the API
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     // Handle error
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}AddQoute`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          token: sessionStorage.getItem("token"),
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
       console.log("forndara",formData);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const responseData = await response.json();
        console.log("responseData--", responseData);
        setSubmitted(true); // Set submitted state to true
        toast.success("Details submitted successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        // Clear form fields
        setFormData({
          Name: "",
          Email: "",
          Country: "",
          PinCode: "",
          DOA: "",
          DOD: "",
          Travelers: "",
          HotelCategory: "",
          AboutIdea: "",
          AboutPlan: "",
          Budget: "",
        });
      } else {
        throw new Error("Response is not in JSON format");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
      toast.error("Error submitting form. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };
  

  return (
    <div className="container-fluid p-0">
      <Nav />
      <div
        className="position-relative d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${help})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="card mb-5 bg-help"
          style={{
            backgroundImage: `url(${bg_help})`,
          }}
        >
          <div className="card-body p-5">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="text-primary fw-semibold mb-4">
                  Get A <span style={{ color: "#0096C7" }}> Free </span> Quote{" "}
                </h3>
                {isLoggedIn ? (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="Name"
                        placeholder="Full Name"
                        className="input w-100"
                        value={formData.Name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="email"
                        name="Email"
                        placeholder="Email"
                        className="input w-100"
                        value={formData.Email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 d-flex gap-2">
                      <input
                        type="text"
                        name="Country"
                        placeholder="Country"
                        className="input w-50"
                        value={formData.Country}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        name="PinCode"
                        placeholder="Pin Code"
                        className="input w-50"
                        value={formData.PinCode}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex gap-2">
                      <input
                        type="date"
                        name="DOA"
                        placeholder="Date of Arrival"
                        className="input w-50"
                        value={formData.DOA}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="date"
                        name="DOD"
                        placeholder="Date of Departure"
                        className="input w-50"
                        value={formData.DOD}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex gap-2">
                      <input
                        type="number"
                        name="Travelers"
                        placeholder="No. of Travelers"
                        className="input w-50"
                        value={formData.Travelers}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="number"
                        name="Budget"
                        placeholder="Budget per head"
                        className="input w-50"
                        value={formData.Budget}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="HotelCategory"
                        placeholder="Hotel Category"
                        className="input w-100"
                        value={formData.HotelCategory}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="AboutPlan"
                        placeholder="Tell us more about your plans."
                        className="input w-100"
                        value={formData.AboutPlan}
                        onChange={handleChange}
                        required
                      />
                       <div className="mb-3">
                      <input
                        type="AboutIdea"
                        name="AboutIdea"
                        placeholder="AboutIdea"
                        className="input w-100"
                        value={formData.AboutIdea}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary w-100">Submit</button>
                    </div>
                  </form>
                ) : (
                  <div>
                    <p>Please log in to access this feature.</p>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/LogIn")}
                    >
                      Login
                    </button>
                  </div>
                )}
               
                {submitted && (
                  <p className="text-success">Details submitted successfully!</p>
                )}

              </div>
              <div className="col-lg-6 text-center d-none d-lg-block">
                <img src={bikeimg} style={{ width: "80%" }} alt="gfg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(HelpMePlan);
