import React, { useState, useEffect } from "react";
import client1 from "../../assets/Images/2.jpeg";
import client2 from "../../assets/Images/anoop.jpeg";
import client3 from "../../assets/Images/srisha.jpeg";

const ClientSays = () => {
  const clients = [
    {
      image: client1,
      desc: (
        <div>
          <p style={{ color: "#080B13" }}>
            I just returned from an absolutely amazing trip, and I can't help
            but share how incredible it was! From the moment I arrived,
            everything exceeded my expectations. The destinations we visited
            were breathtaking, and the itinerary was perfectly balanced between
            must-see landmarks and hidden gems. The accommodations were
            luxurious and comfortable, providing a relaxing retreat after each
            day's adventures. What truly made this trip exceptional was the
            knowledgeable and friendly guide who shared fascinating insights at
            every turn. The group activities were well-organized and added a fun
            social aspect to the journey. Overall, this trip was a seamless
            blend of adventure, relaxation, and cultural immersion. I can't wait
            to book another adventure with this company in the future! Thank you
            CK
          </p>
        </div>
      ),
      name: "Vijay Rathore",
    },
    {
      image: client2,
      desc: (
        <div>
          <p style={{ color: "#080B13" }}>
            I had an incredible experience with Travelers Choice Ladakh during
            my 8-day bike trip to Leh Ladakh. From the start, they exceeded my
            expectations by providing a well-organized and expertly planned
            journey. Our guide, Stanzin Chosphel (CK), was exceptional. His
            knowledge, passion, and friendly nature enhanced the trip, offering
            insights into the region's culture and history. The entire itinerary
            was well-curated, accommodations were comfortable, bikes were
            well-maintained, and safety was a top priority. Travelers Choice
            Ladakh's attention to detail and excellent customer service made the
            trip hassle-free. I highly recommend them for an unforgettable
            adventure, and I'm grateful to CK for his unwavering support. This
            trip will forever be a cherished memory.
          </p>
        </div>
      ),
      name: "Anoop Vince",
    },
    {
      image: client3,
      desc: (
        <div>
          <p style={{ color: "#080B13" }}>
            CK and Tonwang orchestrated an exceptional travel experience,
            ensuring our journey was seamless from start to finish. Their
            attention to detail was evident in every aspect of the trip. Not
            only did they meticulously plan our itinerary, but they also took
            great care to ensure our comfort throughout. From well-organized
            transportation to carefully chosen accommodations, every element of
            the trip reflected their commitment to excellence. Their insights
            transformed each location into more than just a tourist spot—it
            became a story, a part of a larger narrative that made our trip
            truly unforgettable. Moreover, CK and Tonwang's flexibility and
            responsiveness to our needs were commendable. They were always on
            hand to address any questions or concerns, demonstrating a genuine
            commitment to our satisfaction. If you're considering a Ladakh bike
            or car trip, I wholeheartedly recommend CK and Tonwang. Their
            expertise and dedication ensure an unforgettable experience.
          </p>
        </div>
      ),
      name: "Sirisha",
    },
  ];

  const [currentClient, setCurrentClient] = useState(0);
  const [fade, setFade] = useState(true);

  const handleImageClick = (index) => {
    setCurrentClient(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentClient((prevIndex) => (prevIndex + 1) % clients.length);
        setFade(true);
      }, 1500); // Duration of fade-out animation
    }, 5000); // Interval between transitions

    return () => clearInterval(interval);
  }, [clients.length]);

  return (
    <div className="container-fluid my-5">
      <div className="row mx-3">
        <div className="col-md-5 text-center d-none d-md-block">
          <img
            src={clients[currentClient].image}
            alt="selected"
            className={`img-fluid ${fade ? "fade-in" : "fade-out"}`}
            style={{
              width: "450px",
              height: "600px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="col-md-7 px-0 px-md-4 d-flex align-items-center justify-content-start">
          <div>
            <h2 className="text-primary mb-4">
              <b>WHAT OUR CLIENT SAYS</b>
            </h2>
            <div className={`text-left mb-5 ${fade ? "fade-in" : "fade-out"}`}>
              {clients[currentClient].desc}
            </div>
            <div className="row justify-content-center align-items-center ms-0">
              <div
                className="d-flex flex-wrap"
                style={{ gap: "20px" }}
              >
                {clients.map((client, index) => (
                  <div
                    key={index}
                    className="d-flex flex-column align-items-center"
                    style={{ width: "calc(33.333% - 20px)" }}
                  >
                    <img
                      src={client.image}
                      alt={`client${index + 1}`}
                      className="img-fluid mr-3 rounded"
                      style={{
                        width: "100%",
                        height: "175px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => handleImageClick(index)}
                    />
                    <div
                      style={{
                        position: "relative",
                        bottom: "25px",
                        width: "fit-content",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={client.image}
                        className="d-block border"
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                          objectFit: "contain",
                          background: "white",
                        }}
                      />
                      <p className="text-start mb-0">{client.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .fade-in {
            animation: fadeIn 1s forwards;
          }

          .fade-out {
            animation: fadeOut 1s forwards;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes fadeOut {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ClientSays;





//Responsive Part.............

// import React, { useState, useEffect } from "react";
// import client1 from "../../assets/Images/2.jpeg";
// import client2 from "../../assets/Images/anoop.jpeg";
// import client3 from "../../assets/Images/srisha.jpeg";

// const ClientSays = () => {
//   const clients = [
//     {
//       image: client1,
//       desc: (
//         <div>
//           <p style={{ color: "#080B13" }}>
//             I just returned from an absolutely amazing trip, and I can't help
//             but share how incredible it was! From the moment I arrived,
//             everything exceeded my expectations. The destinations we visited
//             were breathtaking, and the itinerary was perfectly balanced between
//             must-see landmarks and hidden gems. The accommodations were
//             luxurious and comfortable, providing a relaxing retreat after each
//             day's adventures. What truly made this trip exceptional was the
//             knowledgeable and friendly guide who shared fascinating insights at
//             every turn. The group activities were well-organized and added a fun
//             social aspect to the journey. Overall, this trip was a seamless
//             blend of adventure, relaxation, and cultural immersion. I can't wait
//             to book another adventure with this company in the future! Thank you
//             CK
//           </p>
//         </div>
//       ),
//       name: "Vijay Rathore",
//     },
//     {
//       image: client2,
//       desc: (
//         <div>
//           <p style={{ color: "#080B13" }}>
//             I had an incredible experience with Travelers Choice Ladakh during
//             my 8-day bike trip to Leh Ladakh. From the start, they exceeded my
//             expectations by providing a well-organized and expertly planned
//             journey. Our guide, Stanzin Chosphel (CK), was exceptional. His
//             knowledge, passion, and friendly nature enhanced the trip, offering
//             insights into the region's culture and history. The entire itinerary
//             was well-curated, accommodations were comfortable, bikes were
//             well-maintained, and safety was a top priority. Travelers Choice
//             Ladakh's attention to detail and excellent customer service made the
//             trip hassle-free. I highly recommend them for an unforgettable
//             adventure, and I'm grateful to CK for his unwavering support. This
//             trip will forever be a cherished memory.
//           </p>
//         </div>
//       ),
//       name: "Anoop Vince",
//     },
//     {
//       image: client3,
//       desc: (
//         <div>
//           <p style={{ color: "#080B13" }}>
//             CK and Tonwang orchestrated an exceptional travel experience,
//             ensuring our journey was seamless from start to finish. Their
//             attention to detail was evident in every aspect of the trip. Not
//             only did they meticulously plan our itinerary, but they also took
//             great care to ensure our comfort throughout. From well-organized
//             transportation to carefully chosen accommodations, every element of
//             the trip reflected their commitment to excellence. Their insights
//             transformed each location into more than just a tourist spot—it
//             became a story, a part of a larger narrative that made our trip
//             truly unforgettable. Moreover, CK and Tonwang's flexibility and
//             responsiveness to our needs were commendable. They were always on
//             hand to address any questions or concerns, demonstrating a genuine
//             commitment to our satisfaction. If you're considering a Ladakh bike
//             or car trip, I wholeheartedly recommend CK and Tonwang. Their
//             expertise and dedication ensure an unforgettable experience.
//           </p>
//         </div>
//       ),
//       name: "Sirisha",
//     },
//   ];

//   const [currentClient, setCurrentClient] = useState(0);
//   const [fade, setFade] = useState(true);

//   const handleImageClick = (index) => {
//     setCurrentClient(index);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setFade(false);
//       setTimeout(() => {
//         setCurrentClient((prevIndex) => (prevIndex + 1) % clients.length);
//         setFade(true);
//       }, 1500); // Duration of fade-out animation
//     }, 5000); // Interval between transitions

//     return () => clearInterval(interval);
//   }, [clients.length]);

//   return (
//     <div className="container-fluid my-5">
//       <div className="row mx-3">
//         <div className="col-md-5 text-center d-none d-md-block">
//           <img
//             src={clients[currentClient].image}
//             alt="selected"
//             className={`img-fluid ${fade ? "fade-in" : "fade-out"}`}
//             style={{
//               width: "100%",
//               height: "auto",
//               maxHeight: "600px",
//               objectFit: "cover",
//             }}
//           />
//         </div>
//         <div className="col-md-7 px-0 px-md-4 d-flex align-items-center justify-content-start">
//           <div>
//             <h2 className="text-primary mb-4">
//               <b>WHAT OUR CLIENT SAYS</b>
//             </h2>
//             <div className={`text-left mb-5 ${fade ? "fade-in" : "fade-out"}`}>
//               {clients[currentClient].desc}
//             </div>
//             <div className="row justify-content-center align-items-center ms-0">
//               <div className="d-flex flex-wrap justify-content-between" style={{ gap: "20px" }}>
//                 {clients.map((client, index) => (
//                   <div
//                     key={index}
//                     className="d-flex flex-column align-items-center"
//                     style={{
//                       flex: "1 0 21%",
//                       marginBottom: "20px",
//                       maxWidth: "calc(33.333% - 20px)",
//                     }}
//                   >
//                     <img
//                       src={client.image}
//                       alt={`client${index + 1}`}
//                       className="img-fluid mr-3 rounded"
//                       style={{
//                         width: "100%",
//                         height: "175px",
//                         cursor: "pointer",
//                         objectFit: "cover",
//                       }}
//                       onClick={() => handleImageClick(index)}
//                     />
//                     <div
//                       style={{
//                         position: "relative",
//                         bottom: "25px",
//                         width: "fit-content",
//                         textAlign: "center",
//                       }}
//                     >
//                       <img
//                         src={client.image}
//                         className="d-block border"
//                         style={{
//                           width: "60px",
//                           height: "60px",
//                           borderRadius: "50%",
//                           objectFit: "contain",
//                           background: "white",
//                         }}
//                       />
//                       <p className="text-start mb-0">{client.name}</p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <style>
//         {`
//           .fade-in {
//             animation: fadeIn 1s forwards;
//           }

//           .fade-out {
//             animation: fadeOut 1s forwards;
//           }

//           @keyframes fadeIn {
//             from {
//               opacity: 0;
//             }
//             to {
//               opacity: 1;
//             }
//           }

//           @keyframes fadeOut {
//             from {
//               opacity: 1;
//             }
//             to {
//               opacity: 0;
//             }
//           }

//           @media (max-width: 768px) {
//             .col-md-5, .col-md-7 {
//               flex: 0 0 100%;
//               max-width: 100%;
//             }

//             .col-md-5 {
//               margin-bottom: 20px;
//             }
//           }

//           @media (max-width: 576px) {
//             .col-md-5 img {
//               height: auto;
//             }

//             .col-md-7 {
//               padding: 0 15px;
//             }
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default ClientSays;
