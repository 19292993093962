import React from 'react';
const Youtube = () => {
  const videoUrl = 'https://www.youtube.com/watch?v=4peMgTL28ts&ab_channel=AnkitBhatia';
  const videoId = extractVideoId(videoUrl);

  function extractVideoId(url) {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    let match;
    while ((match = regex.exec(url)) !== null) {
      if (match[1] === 'v') {
        return match[2];
      }
    }
    return null;
  }

  return (
    <div className='video-container mt-5'>
      {videoId ? (
        <iframe
          width='100%'
          height='500'
          src={`https://www.youtube.com/embed/${videoId}`}
          title='YouTube Video'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      ) : (
        <p>Error: Invalid YouTube URL</p>
      )}
    </div>
  );
};

export default Youtube;
