// import React from 'react'
// import Nav from '../HomePage/Nav'

// const CancellationPolicy = () => {
//   return (
    
//     <>
//     <div className='container' >
//     <Nav/>
//         <div className='container text-center mt-5' >
//         <div className='row '>
//         <h1 style={{marginTop:"2rem"}} ><b> <span style={{color:"#111E43"}} > Cancellation Policy</span></b></h1>
//         </div>  
//         </div>
//     </div>
      
//     </>

//     )
// }

// export default CancellationPolicy


import React from 'react';
import Nav from '../HomePage/Nav';
import "./Cancellation.css";
import Footer from '../HomePage/Footer';
const CancellationPolicy = () => {
  return (
    <>
      <div className='container'>
        <Nav />
        <div className='container text-center mt-5'>
          <div className='row'>
            <h1 style={{ marginTop: '2rem' }}>
              <b>
                {' '}
                <span style={{ color: '#111E43' }}> Cancellation Policy</span>
              </b>
            </h1>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-10 offset-lg-1'>
              <ul className='list-unstyled custom-font'>
                <li>
                  In case of cancellation of the group from the organizer side due to any unavoidable
                  circumstances, we will try to transfer the booking in another departure (if the client
                  denies opting for transfer of booking to other groups then there will be no refund.
                </li>
                <br></br>
                <li>
                  In case of cancellation from the client-side after registration at any point of time, then, in that case, there will be no refund or adjustment of the booking amount.
                </li>
                <br></br>
                <li>
                  Before 60 Days - 30% of the package cost will be deducted from the package cost.
                </li>
                <br></br>
                <li>
                  60-45 Days - 50% of the Package cost will be deducted from the total Package cost.
                </li>
                <br></br>
                <li>
                  45-0 Days - 100% of the package cost i.e. No Refund.
                </li>
                <br></br>
                <li>
                  In case of a change of the departure date: - Unexplored NH reserves the right to change the booking date of the client, in case of customer request before 60 days prior
                </li>
                <br></br>
                <li>
                  NO REFUND ON UNUTILIZED SERVICES OF THE PACKAGES, (IF ANY)
                </li>
                <br></br>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
      <Footer/>
    </>
  );
};

export default CancellationPolicy;
