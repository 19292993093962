// import React, { useState, useEffect } from "react";
// import ProfileNav from "./ProfileNav";
// import "./ProfilePage1.css";
// import { NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons";
// import booking from "../../assets/Images/booking.svg";
// import qrCode from "../../assets/Images/qr.svg";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "./Bookings.css";

// const Bookings = () => {
//   const [showCancelModal, setShowCancelModal] = useState(false);

//   const [bookings, setBookings] = useState([]);

//   useEffect(() => {
//     const fetchBookings = async () => {
//       try {
//         const token = sessionStorage.getItem("token"); // Replace with your actual token
//         const response = await fetch("https://api.travellerchoiceladakh.com/api/MyBooking", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ token }),
//         });
//         if (!response.ok) {
//           throw new Error("Failed to fetch bookings");
//         }
//         const data = await response.json();
//         setBookings(data.data); // Update bookings state with fetched data
//       } catch (error) {
//         console.error("Error fetching bookings:", error.message);
//       }
//     };

//     fetchBookings();
//   }, []);

//   useEffect(() => {
//     // Function to handle modal dismissal
//     const handleModalDismissal = () => {
//       // Remove the backdrop when any modal is closed
//       document.body.classList.remove("modal-open");
//       const modalBackdrops = document.getElementsByClassName("modal-backdrop");
//       while (modalBackdrops[0]) {
//         modalBackdrops[0].parentNode.removeChild(modalBackdrops[0]);
//       }
//     };

//     // Add event listeners when component mounts
//     const modals = document.querySelectorAll(".modal");
//     modals.forEach((modal) => {
//       modal.addEventListener("hidden.bs.modal", handleModalDismissal);
//     });

//     // Remove event listeners when component unmounts
//     return () => {
//       modals.forEach((modal) => {
//         modal.removeEventListener("hidden.bs.modal", handleModalDismissal);
//       });
//     };
//   }, []);

//   // const toggleCancelModal = () => {
//   //   setShowCancelModal(!showCancelModal);
//   // };

//   const handleContinue = () => {
//     const modal2 = document.getElementById("exampleModal2");
//     if (modal2) {
//       modal2.classList.toggle("show");
//       modal2.setAttribute(
//         "aria-hidden",
//         modal2.classList.contains("show") ? "false" : "true"
//       );
//       modal2.setAttribute(
//         "aria-modal",
//         modal2.classList.contains("show") ? "true" : "false"
//       );
//       document.body.classList.toggle("modal-open");
//     }
//   };

//   const hasBookings = true;

//   return (
//     <div className="container-fluid">
//       <div className="row mt-5 ms-5">
//         <div className="col-lg-12">
//           {bookings.length > 0 ? (
//             bookings.map((booking) => (
//               <div key={booking.id} className="row mb-4">
//                 <div className="col-lg-8 d-flex border rounded border-dark">
//                   <div className="col-lg-6">
//                     <div className="border rounded d-flex mt-3 p-3">
//                       <div className="pe-2">
//                         <p>Date: {booking.BookingDate}</p>
//                       </div>
//                       <div className="ps-2" style={{ borderLeft: "1px solid #efefef" }}>
//                         <p>Time: {booking.BookingTime}</p>
//                       </div>
//                     </div>
//                     <div className="p-1">
//                       <p>Number Of Travellers:{booking.NumberOfTraveller}</p>
//                       <p>Trip Duration:{booking.Days}</p>
//                       <p>Booking ID: {booking.BookingId}</p>
//                     </div>
//                   </div>
//                   <div className="col-lg-6 d-flex align-items-center" style={{ borderLeft: "1px solid #000" }}>
//                     <img
//                       src={booking.PackageImage}
//                       alt="Package"
//                       style={{
//                         height: "100px",
//                         width: "100px",
//                         margin: "10px",
//                       }}
//                     />
//                     <div>
//                       <h5 className="card-title ">{booking.BookingPackage}</h5>
//                       <h6 className="card-title mt-1" style={{color:"grey"}}>{booking.BookingName}</h6>
//                       {/* <p>
//                         Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       </p> */}
//                       <p className="mt-2">Price: Rs {booking.Amount}</p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4">
//                   <div className="qr-code-section ps-4">
//                     <img
//                       src={booking.BookingQrCode}
//                       alt="QR Code"
//                       style={{ height: "120px", width: "120px" }}
//                     />
//                     <div className="buttons mt-2 justify-content-between">
//                     <a href={booking.BookingPDF} target="_blank" rel="noopener noreferrer" className="btn btn-primary text-white">
//                         Download Receipt
//                       </a>
//                       <button
//                         type="button"
//                         className="btn btn-danger mt-2"
//                         data-bs-toggle="modal"
//                         data-bs-target="#exampleModal"
//                       >
//                         Cancel Booking
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div
//               className="d-flex justify-content-center align-items-center"
//               style={{ minHeight: "60vh" }}
//             >
//               <h3 style={{ color: "#717171" }}>
//                 <FontAwesomeIcon
//                   icon={faSuitcaseRolling}
//                   style={{ color: "#B197FC", marginRight: "10px" }}
//                 />
//                 Seems you don’t have any bookings yet
//               </h3>
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Cancel Booking Modal */}
//       <div
//         className="modal fade"
//         id="exampleModal"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-xl">
//           <div className="modal-content">
//             <div className="modal-header text-center">
//               <h4 className="modal-title mt-3 " id="exampleModalLabel">
//                 Cancel Reservation
//               </h4>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//               <br />
//             </div>
//             <div className="modal-body text-center">
//               <p>We’re sorry to see you’re not traveling.</p>
//               <h4>
//                 <b>
//                   We’re sorry this trip is not working for you! Could you tell
//                   us why?
//                 </b>
//               </h4>
//               <br />
//               <p style={{ fontSize: "15px", color: "#00000080" }}>
//                 What is the primary reason you are thinking of canceling your
//                 trip?
//               </p>
//               <br />

//               <div className="row">
//                 <div className="col-md-4">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="option1"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="option1"
//                       style={{
//                         fontSize: "14px",
//                         color: "#00000080",
//                         marginRight: "5px",
//                       }}
//                     >
//                       Purchased wrong product
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="option2"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="option2"
//                       style={{
//                         fontSize: "14px",
//                         color: "#00000080",
//                         marginRight: "5px",
//                       }}
//                     >
//                       Lack of facilities
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="option3"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="option3"
//                       style={{
//                         fontSize: "14px",
//                         color: "#00000080",
//                         marginRight: "5px",
//                       }}
//                     >
//                       Difficult to travel at this time
//                     </label>
//                   </div>
//                 </div>
//               </div>

//               <div className="row mt-2">
//                 <div className="col-md-4">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="option4"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="option4"
//                       style={{
//                         fontSize: "14px",
//                         color: "#00000080",
//                         marginRight: "5px",
//                       }}
//                     >
//                       Price is too high
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       name="flexRadioDefault"
//                       id="option5"
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor="option5"
//                       style={{
//                         fontSize: "14px",
//                         color: "#00000080",
//                         marginRight: "5px",
//                       }}
//                     >
//                       Others
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn"
//                 style={{ background: "#7374FF", color: "#FFFFFF" }}
//                 data-bs-dismiss="modal"
//               >
//                 I changed my mind
//               </button>
//               <button
//                 type="button"
//                 className="btn btn-danger mt-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#exampleModal2"
//                 onClick={handleContinue}
//                 style={{ background: "#7374FF", color: "#FFFFFF" }}
//               >
//                 Continue
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Second Modal */}
//       <div
//         className="modal fade"
//         id="exampleModal2"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel2"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-xl">
//           <div className="modal-content">
//             <div className="modal-header text-center">
//               <h4 className="modal-title mt-3 " id="exampleModalLabel2">
//                 Cancel Reservation
//               </h4>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//               <br />
//             </div>
//             <div className="modal-body text-center">
//               <p>We’re sorry to see you’re not traveling.</p>
//               <h4>Cancel the trip</h4>
//               <p>
//                 Take a moment to review the details of your cancellation,
//                 including any applicable fees or refund amounts. Once you're
//                 satisfied, confirm your cancellation.
//               </p>
//               <p>
//                 <b>Terms & Conditions</b>
//               </p>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn"
//                 style={{ background: "#7374FF", color: "#FFFFFF" }}
//                 data-bs-dismiss="modal"
//               >
//                 I changed my mind
//               </button>
//               <button
//                 type="button"
//                 className="btn btn-danger mt-2"
//                 data-bs-toggle="modal"
//                 data-bs-target="#exampleModal3"
//                 style={{ background: "#7374FF", color: "#FFFFFF" }}
//               >
//                 Continue
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Third Modal */}
//       <div
//         className="modal fade"
//         id="exampleModal3"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel3"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-xl">
//           <div className="modal-content">
//             <div className="modal-header text-center">
//               <h3
//                 className="modal-title mt-3 "
//                 id="exampleModalLabel3"
//                 style={{ color: "#00000080" }}
//               >
//                 <b>ThankYou !</b>
//               </h3>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//               <br />
//             </div>
//             <div className="modal-body text-center">
//               {/* <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#63E6BE" }} /> */}
//               <p style={{ color: "#00000080" }}>
//                 Thank you for choosing Travellers choice ladakh! Your
//                 cancellation is confirmed. Your refund will be processed within
//                 7 working days. We appreciate your flexibility. As you plan your
//                 next adventure, remember, we're here for you. Safe travels, and
//                 we look forward to assisting you. If you have any queries, feel
//                 free to reach out. Thank you for being a valued member of our
//                 travel community
//               </p>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 data-bs-dismiss="modal"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default Bookings;

import React, { useState, useEffect } from "react";
import ProfileNav from "./ProfileNav";
import "./ProfilePage1.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons";
import booking from "../../assets/Images/booking.svg";
import qrCode from "../../assets/Images/qr.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Bookings.css";
import axios from "axios";

const Bookings = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await fetch(
          "https://api.travellerchoiceladakh.com/api/MyBooking",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch bookings");
        }
        const data = await response.json();
        console.log("dataa",data);
        setBookings(data.data); // Update bookings state with fetched data
       
      } catch (error) {
        console.error("Error fetching bookings:", error.message);
      }
    };

    fetchBookings();
  }, []);

  useEffect(() => {
    // Function to handle modal dismissal
    const handleModalDismissal = () => {
      // Remove the backdrop when any modal is closed
      document.body.classList.remove("modal-open");
      const modalBackdrops = document.getElementsByClassName("modal-backdrop");
      while (modalBackdrops[0]) {
        modalBackdrops[0].parentNode.removeChild(modalBackdrops[0]);
      }
    };

    // Add event listeners when component mounts
    const modals = document.querySelectorAll(".modal");
    modals.forEach((modal) => {
      modal.addEventListener("hidden.bs.modal", handleModalDismissal);
    });

    // Remove event listeners when component unmounts
    return () => {
      modals.forEach((modal) => {
        modal.removeEventListener("hidden.bs.modal", handleModalDismissal);
      });
    };
  }, []);


  const handleContinue = async (id) => {
    try {
      // Get the selected booking ID from sessionStorage
      const selectedBookingId = sessionStorage.getItem("selectedBookingId");
      const token = sessionStorage.getItem("token");
      const requestBody = {
        token,
        BookingId: selectedBookingId,
        CancellationReason: selectedReason,
      };  
      console.log("reqBody", requestBody);
      const response = await axios.post(
        "https://api.travellerchoiceladakh.com/api/BookingCancel",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (!response.data.status) {
        throw new Error("Failed to cancel booking");
      }
  
      console.log("Cancellation Response:", response.data);
  
      // Update bookings state if cancellation is successful
      if (response.data.status === true) {
        // Update the bookings state to set 'cancelled: true' for the corresponding booking
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.BookingId === selectedBookingId ? { ...booking, cancelled: true } : booking
          )
        );
      }
    } catch (error) {
      console.error("Error cancelling booking:", error.message);
    }
  };
  
  
  
  
  const hasBookings = true;

  return (
    <div className="container-fluid">
      <div className="row mt-0 mt-md-5 mx-3 me-md-0 ms-md-5">
        <div className="col-lg-12">
          {bookings.length > 0 ? (
            bookings.map((booking) => (
              <div key={booking.id} className="row mb-4">
                <div className="col-lg-8 d-flex border rounded border-dark">
                  <div className="col-lg-6">
                    <div className="border rounded d-flex mt-3 p-3">
                      <div className="pe-2">
                        <p>Date: {booking.BookingDate}</p>
                      </div>
                      <div
                        className="ps-2"
                        style={{ borderLeft: "1px solid #efefef" }}
                      >
                        <p>Time: {booking.BookingTime}</p>
                      </div>
                    </div>
                    <div className="p-1">
                      <p>Number Of Travellers:{booking.NumberOfTraveller}</p>
                      <p>Trip Duration:{booking.Days}</p>
                      <p>Booking ID: {booking.BookingId}</p>
                      {booking.cancelled && (
                        <p style={{ color: "red" }}>Cancelled</p>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-lg-6 d-flex align-items-center"
                    style={{ borderLeft: "1px solid #000" }}
                  >
                    <img
                      src={booking.PackageImage}
                      alt="Package"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                    <div>
                      <h5 className="card-title ">{booking.BookingPackage}</h5>
                      <h6 className="card-title mt-1" style={{ color: "grey" }}>
                        {booking.BookingName}
                      </h6>
                      {/* <p>
                        Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p> */}
                      <p className="mt-2">Price: Rs {booking.Amount}</p>
                    </div>
                    {booking.cancelled && (
  <p style={{ color: "red" }}>Cancelled</p>
)}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="qr-code-section ps-4">
                    <img
                      src={booking.BookingQrCode}
                      alt="QR Code"
                      style={{ height: "120px", width: "120px" }}
                    />
                    <div className="buttons mt-2 justify-content-between">
                      <a
                        href={booking.BookingPDF}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary text-white"
                      >
                        Download Receipt
                      </a>
                      <button
                        type="button"
                        className="btn btn-danger mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
    sessionStorage.setItem("selectedBookingId", booking.BookingId);
  }}
                      >
                        Cancel Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "60vh" }}
            >
              <h3 style={{ color: "#717171" }}>
                <FontAwesomeIcon
                  icon={faSuitcaseRolling}
                  style={{ color: "#B197FC", marginRight: "10px" }}
                />
                Seems you don’t have any bookings yet
              </h3>
            </div>
          )}
        </div>
      </div>

      {/* Cancel Booking Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title mt-3 " id="exampleModalLabel">
                Cancel Reservation
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <br />
            </div>
            <div className="modal-body text-center">
              <p>We’re sorry to see you’re not traveling.</p>
              <h4>
                <b>
                  We’re sorry this trip is not working for you! Could you tell
                  us why?
                </b>
              </h4>
              <br />
              <p style={{ fontSize: "15px", color: "#00000080" }}>
                What is the primary reason you are thinking of canceling your
                trip?
              </p>
              <br />

              <div className="row">
                {/* <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option1"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Purchased wrong product
                    </label>
                  </div>
                </div> */}

                <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option1"
                      value="Purchased wrong product" // Add value attribute with the reason
                      onChange={(e) => setSelectedReason(e.target.value)} // Capture selected reason
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option1"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Purchased wrong product
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option2"
                      value="Lack Of Facilities" // Add value attribute with the reason
                      onChange={(e) => setSelectedReason(e.target.value)} // Capture selected reason
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option2"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Lack Of Facilities
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option3"
                      value="Difficult to travel at this time" // Add value attribute with the reason
                      onChange={(e) => setSelectedReason(e.target.value)} // Capture selected reason
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option3"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Difficult to travel at this time
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option4"
                      value="Price is too high" // Add value attribute with the reason
                      onChange={(e) => setSelectedReason(e.target.value)} // Capture selected reason
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option4"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Price is too high
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="option5"
                      value=" Others" // Add value attribute with the reason
                      onChange={(e) => setSelectedReason(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="option5"
                      style={{
                        fontSize: "14px",
                        color: "#00000080",
                        marginRight: "5px",
                      }}
                    >
                      Others
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                style={{ background: "#7374FF", color: "#FFFFFF" }}
                data-bs-dismiss="modal"
              >
                I changed my mind
              </button>
              <button
                type="button"
                className="btn btn-danger mt-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                onClick={() => {
                  setSelectedReason(selectedReason); // Set the selected reason in the state
                 // handleContinue(booking.BookingId);
                }}
                style={{ background: "#7374FF", color: "#FFFFFF" }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Second Modal */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title mt-3 " id="exampleModalLabel2">
                Cancel Reservation
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <br />
            </div>
            <div className="modal-body text-center">
              <p>We’re sorry to see you’re not traveling.</p>
              <h4>Cancel the trip</h4>
              <p>
                Take a moment to review the details of your cancellation,
                including any applicable fees or refund amounts. Once you're
                satisfied, confirm your cancellation.
              </p>
              <p>
                <b>Terms & Conditions</b>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                style={{ background: "#7374FF", color: "#FFFFFF" }}
                data-bs-dismiss="modal"
              >
                I changed my mind
              </button>
              <button
                type="button"
                className="btn btn-danger mt-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
                onClick={() => handleContinue(booking.BookingId)} // Call handleContinue with selectedBookingId
                style={{ background: "#7374FF", color: "#FFFFFF" }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Third Modal */}
      <div
        className="modal fade"
        id="exampleModal3"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel3"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h3
                className="modal-title mt-3 "
                id="exampleModalLabel3"
                style={{ color: "#00000080" }}
              >
                <b>ThankYou !</b>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <br />
            </div>
            <div className="modal-body text-center">
              {/* <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#63E6BE" }} /> */}
              <p style={{ color: "#00000080" }}>
                Thank you for choosing Travellers choice ladakh! Your
                cancellation is confirmed. Your refund will be processed within
                7 working days. We appreciate your flexibility. As you plan your
                next adventure, remember, we're here for you. Safe travels, and
                we look forward to assisting you. If you have any queries, feel
                free to reach out. Thank you for being a valued member of our
                travel community
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
