// import React, { useState } from "react";
// import home from "../../assets/Images/vamshi-vangapally-fH-BSvYeX5g-unsplash.jpg";
// import "./Header.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
// import "bootstrap/dist/css/bootstrap.min.css";

// const Header = () => {
//   const [isChecked, setIsChecked] = useState(false);
//   const [fadeIn, setFadeIn] = useState(false);

//   const handleToggle = () => {
//     setIsChecked(!isChecked);
//     if (!isChecked) {
//       setFadeIn(true); // Apply fade-in effect
//       // Remove fade-in effect after 5 seconds
//       setTimeout(() => {
//         setFadeIn(false); // Remove fade-in effect
//       }, 5000);
//     } else {
//       setFadeIn(false); // Remove fade-in effect immediately
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid p-0 position-relative mb-5">
//         {/* Video or Home Image */}
//         {isChecked ? (
//           <div className={`style ${fadeIn ? 'fade-in' : 'fade-out'}`}>
//             <video
//               autoPlay
//               loop
//               muted
//               className="w-100 main-img"
//               style={{
//                 objectFit:"cover",
//                 transition: "opacity 1s ease-in-out",
//                 opacity: isChecked ? 1 : 0,
//               }}
//             >
//               <source
//                 src="https://viver-myexlusive-server.s3.ap-south-1.amazonaws.com/tcl_upload/Leh_001.mp4"
//                 type="video/mp4"
//               />
//               Your browser does not support the video tag.
//             </video>
//             <div
//               className="navbar-toggler text-center mt-4"
//               style={{
//                 position: "absolute",
//                 bottom: "150px",
//                 left: "50%",
//                 transform: "translateX(-50%)",
//                 zIndex: 999,
//                 transition: "opacity 1.5s ease-in-out",
//                 opacity: isChecked ? 1 : 0,
//               }}
//             >
//               <input
//                 type="checkbox"
//                 id="toggle"
//                 checked={isChecked}
//                 onChange={handleToggle}
//               />
//               <label id="switch" htmlFor="toggle">
//                 <div
//                   id="circle"
//                   className="circle d-flex justify-content-center align-items-center"
//                 >
//                   <FontAwesomeIcon
//                     style={{ color: "#111E43" }}
//                     icon={faAngleDoubleRight}
//                   />
//                 </div>
//               </label>
//               <p
//                 id="text"
//                 style={{
//                   display: isChecked ? "block" : "none",
//                   color: "#FFFFFF",
//                   transition: "opacity 2s ease-in-out",
//                 }}
//               >
//                 Let's Go !!
//               </p>
//             </div>
//           </div>
//         ) : (
//           <div className="position-relative overflow-hidden">
//             <img
//               src={home}
//               alt="Home"
//               className="w-100 main-img"
//               style={{
//                 objectFit: "cover",
//               }}
//             />
//             <div
//               className="overlay-text"
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 textAlign: "center",
//                 width: "80%",
//                 opacity: isChecked ? 0 : 1,
//                 transition: "opacity 2s ease-in-out"
//               }}
//             >
//               <h1 className="text-primary">
//                 <b>Travellers Choice Ladakh</b>
//               </h1>
//               <h2 style={{ color: "#FFFFFF" }}>
//                 <b>One Stop Travel Solution</b>
//               </h2>
//               {/* Main Toggle button */}
//               <div className="navbar-toggler text-center mt-4">
//                 <input
//                   type="checkbox"
//                   id="toggle"
//                   checked={isChecked}
//                   onChange={handleToggle}
//                 />
//                 <label id="switch" htmlFor="toggle">
//                   <div
//                     id="circle"
//                     className="circle d-flex justify-content-center align-items-center"
//                   >
//                     <FontAwesomeIcon
//                       style={{ color: "#111E43" }}
//                       icon={faAngleDoubleRight}
//                     />
//                   </div>
//                   <p
//                     id="text"
//                     style={{
//                       display: isChecked ? "none" : "block",
//                       color: "#111E43",
//                     }}
//                   >
//                     Craft Your Perfect Escape Here!
//                   </p>
//                 </label>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Header;



import React, { useState } from "react";
import home from "../../assets/Images/vamshi-vangapally-fH-BSvYeX5g-unsplash.jpg";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setFadeIn(true); // Apply fade-in effect
      // Remove fade-in effect after 5 seconds
      setTimeout(() => {
        setFadeIn(false); // Stop changing opacity after fade-in effect
      }, 5000);
    } else {
      setFadeIn(false); // Remove fade-in effect immediately
    }
  };

  return (
    <>
      <div className="container-fluid p-0 position-relative mb-5">
        {/* Video or Home Image */}
        {isChecked ? (
          <div className={`style ${fadeIn ? 'fade-in' : ''}`}>
            <video
              autoPlay
              loop
              muted
              className="w-100 main-img"
              style={{
                objectFit: "cover",
                transition: "opacity 1s ease-in-out",
                opacity: 1, // Ensure opacity remains at 1
              }}
            >
              <source
                src="https://viver-myexlusive-server.s3.ap-south-1.amazonaws.com/tcl_upload/Leh_001.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div
              className="navbar-toggler text-center mt-4"
              style={{
                position: "absolute",
                bottom: "150px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 999,
                transition: "opacity 1.5s ease-in-out",
                opacity: isChecked ? 1 : 0,
              }}
            >
              <input
                type="checkbox"
                id="toggle"
                checked={isChecked}
                onChange={handleToggle}
              />
              <label id="switch" htmlFor="toggle">
                <div
                  id="circle"
                  className="circle d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon
                    style={{ color: "#111E43" }}
                    icon={faAngleDoubleRight}
                  />
                </div>
              </label>
              <p
                id="text"
                style={{
                  display: isChecked ? "block" : "none",
                  color: "#FFFFFF",
                  transition: "opacity 2s ease-in-out",
                }}
              >
                Let's Go !!
              </p>
            </div>
          </div>
        ) : (
          <div className="position-relative overflow-hidden">
            <img
              src={home}
              alt="Home"
              className="w-100 main-img"
              style={{
                objectFit: "cover",
              }}
            />
            <div
              className="overlay-text"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "80%",
                opacity: isChecked ? 0 : 1,
                transition: "opacity 2s ease-in-out"
              }}
            >
              <h1 className="text-primary">
                <b>Travellers Choice Ladakh</b>
              </h1>
              <h2 style={{ color: "#FFFFFF" }}>
                <b>One Stop Travel Solution</b>
              </h2>
              {/* Main Toggle button */}
              <div className="navbar-toggler text-center mt-4">
                <input
                  type="checkbox"
                  id="toggle"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <label id="switch" htmlFor="toggle">
                  <div
                    id="circle"
                    className="circle d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#111E43" }}
                      icon={faAngleDoubleRight}
                    />
                  </div>
                  <p
                    id="text"
                    style={{
                      display: isChecked ? "none" : "block",
                      color: "#111E43",
                    }}
                  >
                    Craft Your Perfect Escape Here!
                  </p>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

