// import React, { useState, useEffect } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import axios from "axios";
// import { Link } from "react-router-dom";

// const Services = () => {
//   const [images, setImages] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const responsive = {
//     superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
//     desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
//     tablet: { breakpoint: { max: 1024, min: 768 }, items: 3 },
//     mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
//   };

//   useEffect(() => {
//     const fetchServices = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(
//           "https://api.travellerchoiceladakh.com/api/HotSelling"
//         );
//         if (response.data.status === "true") {
//           setImages(response.data.data);
//         }
//       } catch (error) {
//         setError("Error fetching data");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchServices();
//   }, []);

//   return (
//     <div className="container mt-4">
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error}</p>}
//       <Carousel
//         responsive={responsive}
//         containerClass="carousel-container"
//         itemClass="carousel-item-padding-0"
//       >
//         {images.map((image) => (
//           <div key={image.Id} className="w-100 mx-4">
//             {image.Id === 1 ? (
//               <Link to="/HotelCard" className="w-100">
//                 <img
//                   src={image.ServiceImage}
//                   className="rounded w-100 zoom-img"
//                   alt={image.Name}
//                   style={{
//                     height: "500px", 
//                     objectFit: "cover",
//                   }}
//                 />
//               </Link>
//             ) : (
//               <Link to={`/TripPackages/${image.Id}`} className="w-100 mx-4">
//                <img
//                  src={image.ServiceImage}
//                  className="rounded w-100 zoom-img"
//                  alt={image.Name}
//                  style={{
//                     height: "500px", 
//                     objectFit: "cover",
//                   }}
//                />
//            </Link>
//             )}
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default Services;







  // import React, { useState, useEffect, useRef } from "react";
  // import Carousel from "react-multi-carousel";
  // import "react-multi-carousel/lib/styles.css";
  // import axios from "axios";
  // import { Link } from "react-router-dom";
  // import "./Services.css"; // Import the CSS file

  // const Services = () => {
  //   const [images, setImages] = useState([]);
  //   const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState(null);
  //   const imageRefs = useRef([]);

  //   const responsive = {
  //     superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
  //     desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  //     tablet: { breakpoint: { max: 1024, min: 768 }, items: 3 },
  //     mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  //   };

  //   useEffect(() => {
  //     const fetchServices = async () => {
  //       setLoading(true);
  //       try {
  //         const response = await axios.get(
  //           "https://api.travellerchoiceladakh.com/api/HotSelling"
  //         );
  //         if (response.data.status === "true") {
  //           setImages(response.data.data);
  //         }
  //       } catch (error) {
  //         setError("Error fetching data");
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchServices();
  //   }, []);

  //   useEffect(() => {
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         entries.forEach((entry) => {
  //           if (entry.isIntersecting) {
  //             entry.target.classList.add("active");
  //           } else {
  //             entry.target.classList.remove("active");
  //           }
  //         });
  //       },
  //       { threshold: 0.1 }
  //     );

  //     imageRefs.current.forEach((ref) => {
  //       if (ref) {
  //         observer.observe(ref);
  //       }
  //     });

  //     return () => {
  //       imageRefs.current.forEach((ref) => {
  //         if (ref) {
  //           observer.unobserve(ref);
  //         }
  //       });
  //     };
  //   }, [images]);

  //   return (
  //     <div className="container mt-4">
  //       {loading && <p>Loading...</p>}
  //       {error && <p>Error: {error}</p>}
  //       <Carousel
  //         responsive={responsive}
  //         containerClass="carousel-container"
  //         itemClass="carousel-item-padding-0"
  //       >
  //         {images.map((image, index) => (
  //           <div
  //             key={image.Id}
  //             className="w-100 mx-4 reveal"
  //             ref={(el) => (imageRefs.current[index] = el)}
  //           >
  //             {image.Id === 1 ? (
  //               <Link to="/HotelCard" className="w-100">
  //                 <img
  //                   src={image.ServiceImage}
  //                   className="rounded w-100 zoom-img"
  //                   alt={image.Name}
  //                   style={{
  //                     height: "500px",
  //                     objectFit: "cover",
  //                   }}
  //                 />
  //               </Link>
  //             ) : (
  //               <Link to={`/TripPackages/${image.Id}`} className="w-100 mx-4">
  //                 <img
  //                   src={image.ServiceImage}
  //                   className="rounded w-100 zoom-img"
  //                   alt={image.Name}
  //                   style={{
  //                     height: "500px",
  //                     objectFit: "cover",
  //                   }}
  //                 />
  //               </Link>
  //             )}
  //           </div>
  //         ))}
  //       </Carousel>
  //     </div>
  //   );
  // };

  // export default Services;



import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Services.css"; // Import the CSS file

const Services = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const imageRefs = useRef([]);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  };

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.travellerchoiceladakh.com/api/HotSelling"
        );
        if (response.data.status === "true") {
          setImages(response.data.data);
        }
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
            entry.target.classList.remove("active");
          }
        });
      },
      { threshold: 0.1 }
    );

    imageRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      imageRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [images]);

  const handleImageClick = (id, name) => {
    sessionStorage.setItem('tripPackageId', id);
    const link = id === 1 ? '/Ladakh-Stay' : `/TripPackages/${name}`;
    navigate(link);
  };

  return (
    <div className="container mt-4">
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-0"
      >
        {images.map((image, index) => (
          <div
            key={image.Id}
            className="w-100 mx-4 reveal"
            ref={(el) => (imageRefs.current[index] = el)}
          >
            <div
              onClick={() => handleImageClick(image.Id, image.Name)}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={image.ServiceImage}
                className="rounded w-100 zoom-img"
                alt={image.Name}
                style={{
                  height: "500px",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Services;
