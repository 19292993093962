// import React from 'react';
// const Quotations = () => {
//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">

//           <div className="col-lg-12">
//             <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
//               <h3 style={{ color: "#717171" }}>
//                 No Quotations Found
//               </h3>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Quotations;

// // https://api.travellerchoiceladakh.com/api/MyQoute

// import React, { useState, useEffect } from 'react';

// const Quotations = () => {
//   const [quotations, setQuotations] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = sessionStorage.getItem('token');

//     // Check if token exists and user is authenticated
//     if (token) {
//       setIsAuthenticated(true);

//       fetch('https://api.travellerchoiceladakh.com/api/MyQoute', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           token: token,
//         }),
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.status === 'true') {
//             setQuotations(data.data);
//           } else {
//             setQuotations([]);
//           }
//           setIsLoading(false);
//         })
//         .catch((error) => {
//           console.error('Error fetching quotations:', error);
//           setIsLoading(false);
//         });
//     } else {
//       setIsAuthenticated(false);
//       setIsLoading(false);
//     }
//   }, []);

//   return (
//     <>
//       {isLoading ? (
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
//                 <h3 style={{ color: '#717171' }}>Loading...</h3>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-lg-12">
//               {isAuthenticated && quotations.length > 0 ? (
//                 <div className="row">
//                   {quotations.map((quote) => (
//                     <div className="col-lg-4 mb-4" key={quote.id}>
//                       <div className="card">
//                         <div className="card-body">
//                           <h5 className="card-title">{quote.name}</h5>
//                           <p className="card-text">Email: {quote.email}</p>
//                           <p className="card-text">Country: {quote.country}</p>
//                           {/* Add more fields as needed */}
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
//                   <h3 style={{ color: '#717171' }}>No Quotations Found</h3>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Quotations;

import React, { useState, useEffect } from "react";

const Quotations = () => {
  const [quotations, setQuotations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    // Check if token exists and user is authenticated
    if (token) {
      setIsAuthenticated(true);

      fetch("https://api.travellerchoiceladakh.com/api/MyQoute", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "true") {
            setQuotations(data.data);
          } else {
            setQuotations([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching quotations:", error);
          setIsLoading(false);
        });
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "60vh" }}
              >
                <h3 style={{ color: "#717171" }}>Loading...</h3>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              {isAuthenticated && quotations.length > 0 ? (
                <div className="row">
                  {quotations.map((quote) => (
                    <div className="col-lg-4 mb-4" key={quote.id}>
                      <div
                        className="card m-3"
                        style={{
                          boxShadow:
                            "0px 1.070197343826294px 10.701974868774414px 0px #0000001A",
                          borderStyle: "none",
                        }}
                      >
                        <div className="card-body">
                          <h5 className="card-title">{quote.name}</h5>
                          <hr />
                          <p className="card-text">
                            <strong>Email:</strong> {quote.email}
                          </p>
                          <p className="card-text">
                            <strong>Country:</strong> {quote.country}
                          </p>
                          <p className="card-text">
                            <strong>Pincode:</strong> {quote.pincode}
                          </p>
                          <p className="card-text">
                            <strong>Date of Arrival:</strong> {quote.DOA}
                          </p>
                          <p className="card-text">
                            <strong>Date of Departure:</strong> {quote.DOD}
                          </p>
                          <p className="card-text">
                            <strong>Travelers:</strong> {quote.Travelers}
                          </p>
                          <p className="card-text">
                            <strong>Hotel Category:</strong>{" "}
                            {quote.Hotel_category}
                          </p>
                          <p className="card-text">
                            <strong>About Idea:</strong> {quote.about_idea}
                          </p>
                          <p className="card-text">
                            <strong>About Plan:</strong> {quote.about_plan}
                          </p>
                          <p className="card-text">
                            <strong>Budget:</strong> {quote.budget}
                          </p>
                          {/* <p className="card-text">
                            <strong>User ID:</strong> {quote.UserId}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "60vh" }}
                >
                  <h3 style={{ color: "#717171" }}>No Quotations Found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Quotations;
