import React from 'react';
import Nav from '../HomePage/Nav';
import './Cancellation.css';
import Footer from '../HomePage/Footer';

const TermsNConditions = () => {
  return (
    <>
      <div className='container'>
        <Nav />
        <div className='container text-center mt-5'>
          <div className='row'>
            <h1 style={{ marginTop: '2rem' }}>
              <b>
                {' '}
                <span style={{ color: '#111E43' }}> Terms Of Use </span>
              </b>
            </h1>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-10 offset-lg-1'>
              <ul className='list-unstyled custom-font'>
                <li>
                  In case of cancellation of the group from the organizer side due to any unavoidable
                  circumstances, we will try to transfer the booking in another departure (if the client
                  denies opting for transfer of booking to other groups then there will be no refund.
                </li><br></br>
                <li>
                  In case of cancellation from the client-side after registration at any point of time, then, in that case, there will be no refund or adjustment of the booking amount.
                </li><br></br>
                <li>
                  Before 60 Days - 30% of the package cost will be deducted from the package cost.
                </li><br></br>
                <li>
                  60-45 Days - 50% of the Package cost will be deducted from the total Package cost.
                </li><br></br>
                <li>
                  45-0 Days - 100% of the package cost i.e. No Refund.
                </li><br></br>
                <li>
                  In case of a change of the departure date: - Unexplored NH reserves the right to change the booking date of the client, in case of customer request before 60 days prior
                </li>
                <li><br></br>
                  NO REFUND ON UNUTILIZED SERVICES OF THE PACKAGES, (IF ANY)
                </li><br></br>
                <li>
                  Anything that is explicitly not mentioned in the inclusions and optional as suggested in
                  the itinerary is not a part of this package. Images shown about the place, city, hotel,
                  attractions, etc. are for illustration purposes only and may not be the same as linked to
                  the itinerary or services provided.
                </li><br></br>
                <li>
                  Cost and consequences arising due to any force majeure situations including but not
                  limited to unforeseen circumstances like; natural calamities, political disturbances,
                  health issues, flight delays/cancellations, things beyond our control, etc. will have to be
                  borne by the guest.
                </li><br></br>
                <li>
                  Airport transfers and coach tours are based on all guest traveling together on the
                  same flight, any additional transfers will not be provided if guest travel on different
                  airlines.
                </li><br></br>
                <li>
                  There will be additional charges for the passengers who would like to stay back at the
                  destination or in transit.
                </li><br></br>
                <li>
                  Unexplored NH reserve the right to allow/reject If you are carrying gifts, brochures,
                  standees, placards, t-shirts, stationery, backdrops, laptops, projector, etc. for
                  distribution, Promotion, or commercial purposes.
                </li><br></br>
                <li>
                  Duties or gratuities, if any will be payable by the guest/corporate only.
                  Allowing excess luggage is subject to the Unexplored NH approval if any such charges
                  will be payable by guest/corporate only.
                </li><br></br>
                <li>
                  Any changes in the itinerary or upgrade in hotels will be charged extra, over and above
                  the given cost (which will be informed to the customer in advance for final acceptance
                  and payment).
                </li><br></br>
                <li>
                  We shall not be responsible for any change in the itinerary due to landslides, flight
                  cancellations, weather conditions, or any unforeseen circumstances. The additional
                  cost incurred will be in addition to the cost which will be borne by the client.
                </li><br></br>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsNConditions;
