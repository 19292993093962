import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Nav from '../HomePage/Nav';
import Footer from '../HomePage/Footer';
import BlueNav from '../HomePage/BlueNav';

const BlogInner = () => {
  const { Id } = useParams(); // Get the Id parameter from the URL
  const [blog, setBlog] = useState(null); // State to store the fetched blog details
  
  useEffect(() => {
    const fetchBlogDetails = async () => {
        try {
          const response = await fetch(`https://api.travellerchoiceladakh.com/api/BlogDetails/${Id}`);
          const data = await response.json();
      
          if (data.status === "true" && data.data) {
            setBlog(data.data); // Set the fetched blog details to the state
            console.log("inner-",blog );
          } else {
            console.error('Error fetching blog details: Invalid response data');
          }
        } catch (error) {
          console.error('Error fetching blog details:', error);
        }
      };

    fetchBlogDetails();
  }, [Id]);
  
  console.log("idd-",Id);

  return (
    <>
      {blog ? (
        <div className='container-fluid'>
          <Nav/>
          <div
            className="container-fluid p-0 position-relative mb-5"
            style={{
              backgroundImage: `url(${blog.Image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end", // Align items to the bottom
              position: "relative",
            }}
          >
            {/* Overlay text */}
            <div className="overlay-text" style={{ position: 'absolute' }}>
              <h2 style={{ color: "#FFFFFF" }}>
                <b>{blog.Title}</b>
              </h2>
              <p style={{ marginTop: "2rem", color: "#FFFFFF" }}>by <b><span style={{color:"#FFA902"}} >{blog.Post}</span></b></p>
            </div>
          </div>
          <div className='container'
        dangerouslySetInnerHTML={{ __html: blog.LongDescription }}
      ></div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <Footer/>
    </>
  );
};

export default BlogInner;
