import React, { useEffect, useState } from "react";
import BlueNav from "../HomePage/BlueNav";

const BookOnline = () => {
  const [packageName, setPackageName] = useState("");
  const [people, setPeople] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      date: "",
      age: "",
    },
  ]);
  const [packageDetails, setPackageDetails] = useState({
    name: "",
    location: "",
    days: "",
    price: "",
    image: "",
    TourDate: [],
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(""); // Define selectedDate state

//  console.log("sId",sessionStorage.getItem("selectedId"));

  useEffect(() => {
    const fetchData = async () => {
      // Check if user is logged in
      const loggedIn = sessionStorage.getItem("isLoggedIn");
      setIsLoggedIn(loggedIn);
      const slug = sessionStorage.getItem("slugg");
      
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllPackages`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // console.log("dataata-",data);
        // const selectedPackage = data.data.find(
        //   (demo) => demo.id === parseInt(sessionStorage.getItem("selectedId"))
        // );
        const selectedPackage = data.data.find(
          (demo) => demo.slug === slug
        );
         //console.log("selec-",selectedPackage)
        //console.log("selectedpackg id dddd --", selectedPackage.category.id);

        if (selectedPackage) {
          const { name, location, days, price, image, TourDate } =
            selectedPackage;
          setPackageDetails({
            name,
            location,
            days,
            price,
            image,
            TourDate,
          });
          setPackageName(name);

          // Set initial totalPrice and adultCount based on package details
          setTotalPrice(parseInt(price));
          setAdultCount(1); // Default adult count
        } else {
          throw new Error("Package not found");
        }
      } catch (error) {
        console.error("Error fetching package details:", error.message);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   console.log("Selected Package ID:", sessionStorage.getItem("selectedId"));
  
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}AllPackages`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data");
  //       }
  //       const data = await response.json();
  //       console.log("API Response:", data);
        
  //       const selectedPackage = data.data.find(
  //         (demo) => demo.id === parseInt(sessionStorage.getItem("selectedId"))
  //       );
  
  //       console.log("Selected Package:", selectedPackage);
  
  //       if (selectedPackage) {
  //         const { name, location, days, price, image, TourDate } = selectedPackage;
  //         setPackageDetails({
  //           name,
  //           location,
  //           days,
  //           price,
  //           image,
  //           TourDate,
  //         });
  //         setPackageName(name);
  
  //         // Set initial totalPrice and adultCount based on package details
  //         setTotalPrice(parseInt(price));
  //         setAdultCount(1); // Default adult count
  //       } else {
  //         throw new Error("Package not found");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching package details:", error.message);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
    setSelectedDate(date); // Update selectedDate state
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };



  const handleAddPerson = () => {
    const updatedPeople = [
      ...people,
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        date: "",
        age: "",
      },
    ];
    const totalPrice = parseInt(packageDetails.price) * updatedPeople.length;
    const adultCount = updatedPeople.length; // Assuming all people are adults

    setPeople(updatedPeople);
    setTotalPrice(totalPrice);
    setAdultCount(adultCount);
  };

  const handleDeletePerson = (index) => {
    const updatedPeople = people.filter((_, i) => i !== index);
    setPeople(updatedPeople);
    const totalPrice = parseInt(packageDetails.price) * updatedPeople.length;
    const adultCount = updatedPeople.length; // Assuming all people are adults
    setTotalPrice(totalPrice);
    setAdultCount(adultCount);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPeople = [...people];

    if (name === "date") {
      updatedPeople[index][name] = value;
      setSelectedDate(value); // Update selectedDate state
    } else if (name === "age") {
      updatedPeople[index][name] = Math.max(0, parseInt(value)); // Ensure age is not below 0
    } else if (name === "phone") {
      // Validate phone number length
      if (value.length <= 10) {
        updatedPeople[index][name] = value;
      }
    } else {
      updatedPeople[index][name] = value;
    }

    setPeople(updatedPeople);
  };
  
  // console.log("selectedSessinonIdff",sessionStorage.getItem("selectedId"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted People:", people);
    console.log("Token:", sessionStorage.getItem("token"));
    console.log("Total Price:", totalPrice);

    const requestData = prepareFormData();
    console.log("reqData--", requestData);
    try {
      const response = await fetch(
        "https://api.travellerchoiceladakh.com/api/PhonePeData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            cors: "no-cors",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
      const responseData = await response.json();
      console.log("Response11-:", responseData);

      const { data } = responseData;
      const redirectUrl = `https://api.travellerchoiceladakh.com/api/phonePe/${data}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Error submitting data:", error.message);
      alert("Error submitting data: " + error.message);
    }
  };

  const prepareFormData = () => {
    const travellerDetail = {};
    people.forEach((person, index) => {
      travellerDetail[index.toString()] = [
        person.firstName,
        person.lastName,
        person.email,
        person.phone,
        person.age,
      ];
    });
  
    const requestData = {
      TravellerDetail: travellerDetail,
      PackageId: sessionStorage.getItem("selectedId"),
      UserId: sessionStorage.getItem("token"),
      Amount: totalPrice,
      BookingId: generateRandomNumber(),
      BikeDate: selectedDate,
      FromDate:null,
      ToDate:null,
      WithFood:0
    };
  
    console.log("reqData--", requestData);
    return requestData;
  };
  
  

 
  

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * 100000);
    const randomId = `TCL${randomNumber.toString().padStart(5, "0")}`;
    return randomId;
  };

  const redirectToLogin = () => {
    window.location.href = "/login";
  };
  // console.log("packagedetails",packageDetails);
  return (
    <>
      <BlueNav />
      <div className="container mt-5">
        {isLoggedIn ? (
          <div className="row pt-5">
            <div className="col-lg-7">
                <div>
                  <h4>
                    <b>{packageName}</b>
                  </h4>
                </div>
                <h4>Contact Details</h4>
                <h6>
                  We'll use this information to send you confirmation and
                  updates about your booking.
                </h6>
                {people.map((person, index) => (
                  <form key={index} onSubmit={handleSubmit}>
                    <div className="row border rounded mt-3 p-3">
                      <div className="mb-3 col-md-6 pe-2">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={person.firstName}
                          onChange={(e) => handleChange(index, e)}
                          placeholder="First Name"
                          required // Make the field required
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          value={person.lastName}
                          onChange={(e) => handleChange(index, e)}
                          placeholder="Last Name"
                          required // Make the field required
                        />
                      </div>
                      <div className="mb-3 col-md-6 pe-2">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={person.email}
                          onChange={(e) => handleChange(index, e)}
                          placeholder="Email"
                          required // Make the field required
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Email pattern validation
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          value={person.phone}
                          onChange={(e) => handleChange(index, e)}
                          placeholder="Phone Number"
                          maxLength="10" // Limit input to 10 characters
                          required // Make the field required
                        />
                      </div>
                      <div className="mb-3 col-md-6 pe-2">
                        <select
                          className="form-select"
                          value={person.date}
                          onChange={(e) => handleChange(index, e)}
                          name="date"
                          required // Make the field required
                        >
                          <option value="">Select Date</option>
                          {packageDetails.TourDate.map((date) => (
                            <option key={date} value={date}>
                              {date}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-3">
                        <input
                          type="number"
                          className="form-control"
                          name="age"
                          value={person.age}
                          onChange={(e) => handleChange(index, e)}
                          placeholder="Age"
                          required // Make the field required
                        />
                      </div>
                    </div>

                    {index !== 0 && (
                      <button
                        type="button"
                        className="btn btn-danger mb-3"
                        onClick={() => handleDeletePerson(index)}
                      >
                        Delete
                      </button>
                    )}
                  </form>
                ))}

                <div className="text-end mt-4">
                  {people.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-primary mb-3"
                      onClick={handleAddPerson}
                    >
                      Add More People
                    </button>
                  )}
                  <button
                    type="submit"
                    className="btn btn-success ms-2 mb-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="border rounded p-3 shadow-sm ms-0 ms-lg-4">
                  <h4 className="m-2">Reservation Summary</h4>
                  <hr />
                  <div className="d-flex">
                    <div>
                      {" "}
                      <img
                        src={packageDetails.image[0]}
                        alt="Package Image"
                        width="200"
                        height="118"
                        className="img-fluid rounded"
                      />
                    </div>
                    <div
                      className="ms-2"
                      style={{ fontSize: "16px", lineHeight: "1.2" }}
                    >
                      <p className="mb-2 fw-semibold">{packageDetails.name}</p>
                      <p className="mb-2">{packageDetails.location}</p>
                      <p className="mb-2">Days- {packageDetails.days}</p>
                      <p className="mb-2">Adults- {adultCount} </p>
                    </div>
                  </div>

                  <h5 className="mt-4">
                    Total price = <b>Rs {totalPrice}</b>{" "}
                  </h5>
                </div>
              </div>
          </div>
        ) : (
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6 mt-5 text-center">
              <h3>Please log in to access this page</h3>
              <button
                className="btn btn-primary mt-3"
                onClick={redirectToLogin}
              >
                Log In
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookOnline;
