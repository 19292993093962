import React, { useState, useEffect } from "react";
import BlogHeader from "./BlogHeader";
import FeaturedExplore from "./FeaturedExplore";
import LatestBlog from "./LatestBlog";
import Youtube from "./Youtube";
import Footer from "../HomePage/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Blogs = () => {
  const [images, setImages] = useState([]);



  useEffect(() => {
    const fetchInstagramImages = async () => {
      try {
        const response = await fetch(
          "https://api.travellerchoiceladakh.com/api/InstagramImages"
        );
        const data = await response.json();
        console.log("Fetched data:", data); // Check the structure of data
        if (data.status === "true" && data.data) {
          setImages(data.data);
        } else {
          console.error("Error fetching Instagram images: Invalid response data");
        }
      } catch (error) {
        console.error("Error fetching Instagram images:", error);
      }
    };
  
    fetchInstagramImages();
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container-fluid">
      <BlogHeader />
      <FeaturedExplore />
      <LatestBlog />
      <Youtube />

      <div className="text-center my-5">
        <h4 className="mt-3">
          <b>Follow Me On Instagram</b>
        </h4>
        <div>
          <a
            href="https://www.instagram.com/travellers_choice_ladakh/?next=%2F"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h6 style={{ color: "#111E43" }}>
              <b>@travellers_choice_ladakh</b>
            </h6>
          </a>
        </div>
      </div>

      <div className="container mt-4">
        
        <Carousel
  responsive={responsive}
  containerClass="carousel-container"
  itemClass="carousel-item-padding-0"
>
  {images.map((image, index) => (
    <div key={index}>
      <div className="card my-3 border-0">
        <img
          src={images.image} // Check if this path is correct
          className="card-img-top w-100 zoom-img"
          alt={`Instagram Image ${index + 1}`}
          style={{
            height: images.length ? (index === Math.floor(images.length / 2) ? "500px" : "450px") : "450px",
          }}
        />
      </div>
    </div>
  ))}
</Carousel>

      </div>

      <Footer />
    </div>
  );
};

export default Blogs;