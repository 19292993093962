import React from "react";
import Nav from "../HomePage/Nav";
import blogHome from "../../assets/Images/blogHome.svg";
const BlogHeader = () => {
  return (
    <>
      <div className="container-fluid">
        <Nav />
        <div
          className="container-fluid p-0 position-relative mb-5"
          style={{
            backgroundImage: `url(${blogHome})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Overlay text */}
          <div className="overlay-text">
            <h1 style={{ color: "#FFFFFF" }}>
              <b>Where will you go next?</b>
            </h1>
            <h6 style={{ color: "#FFFFFF" }}>
              This blog section will provide valuable insights and information
              for travelers planning a trip to Ladakh. It will cover various
              aspects of traveling to Ladakh, including the best time to visit,
              how to reach Ladakh, places to visit, and tips for a safe and
              enjoyable trip.
            </h6>
            <h2 style={{ marginTop: "2rem", color: "#FFA902" }}>
              <b>Lets Go....</b>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogHeader;
