// import React, { useState } from "react";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";

// function ForgetPassword() {
//   const [formData, setFormData] = useState({
//     username: "", // Changed state variable from emailOrPhone to username
//   });
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log("Form submitted:", formData);

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}forgotPassword`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Password reset request successful");
//         // Save token in session storage
//         sessionStorage.setItem("resetToken", data.token);
//         // Navigate to ForgetOtp page
//         navigate("/ForgetOtp");
//       } else {
//         console.error("Password reset request failed");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row justify-content-center">
//         <div className="col-lg-5 p-0 d-none d-lg-block">
//         <div
//             className="w-100 h-100"
//             style={{
//               backgroundImage: `url(${LeftImage})`,
//               objectFit:"cover",
//                filter: "blur(1px)",
//               // webkitBackdropFilter: "blur(10px)",
//             }}
//           >
//             <div className="ms-3">
//               <h3 style={{ color: "#FFFFFF" }}><b>trxvllrschoice</b></h3>
//             </div>
//             <div className="h-75 d-flex justify-content-center align-items-center">
//               <h4 style={{ color: "#FFFFFF" }}><b>LADAKH</b></h4>
//             </div>
//           </div>
//         </div>
//         <div
//           className="col-lg-7 "
//           style={{ backgroundColor: "#03045E", height: "100vh" }}
//         >
//           <div className="h-100 d-flex align-items-center justify-content-center">
//             <div
//               className="card mx-2 custom-card "
//               style={{ maxheight: "550px", borderRadius: "20px" }}
//             >
//               <div className="card-body p-5" style={{ height: "100%" }}>
//                 <h2 className="card-title" style={{ color: "#03045E" }}>
//                   Forget Password
//                 </h2>
//                 <p className="text-muted" style={{ fontSize: "small" }}>
//                   Enter your username to reset your password
//                 </p>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <input
//                       type="text"
//                       name="username"
//                       placeholder="Email/Phone" 
//                       value={formData.username} 
//                       onChange={handleChange}
//                       className="form-control"
//                       required
//                     />
//                   </div>
//                   <div className="text-center">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         borderRadius: "20px",
//                         backgroundColor: "#03045E",
//                         color: "white",
//                       }}
//                     >
//                       Submit
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ForgetPassword;







import React, { useState } from "react";
import LeftImage from "../../../assets/Images/a1a1.png";
import { Link, useNavigate } from "react-router-dom";

// Define the spinner component
const Spinner = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

function ForgetPassword() {
  const [formData, setFormData] = useState({
    username: "", // Changed state variable from emailOrPhone to username
  });
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting the form

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}forgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Password reset request successful");
        // Save token in session storage
        sessionStorage.setItem("resetToken", data.token);
        // Navigate to ForgetOtp page
        navigate("/ForgetOtp");
      } else {
        console.error("Password reset request failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false); // Set loading state to false after form submission or error handling
      setFormData({
        username: "", // Reset the form data
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-5 p-0 d-none d-lg-block">
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${LeftImage})`,
              objectFit: "cover",
              filter: "blur(1px)",
            }}
          >
            <div
                className="ms-3"
                style={{ cursor: "pointer", color: "#FFFFFF" }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <h3>
                  <b>trxvllrschoice</b>
                </h3>
              </div>
              
            <div className="h-75 d-flex justify-content-center align-items-center">
              <h4 style={{ color: "#FFFFFF" }}>
                <b>LADAKH</b>
              </h4>
            </div>
          </div>
        </div>
        <div
          className="col-lg-7 "
          style={{ backgroundColor: "#03045E", height: "100vh" }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div
              className="card mx-2 custom-card "
              style={{ maxHeight: "550px", borderRadius: "20px" }}
            >
              <div className="card-body p-5" style={{ height: "100%" }}>
                <h2 className="card-title" style={{ color: "#03045E" }}>
                  Forget Password
                </h2>
                <p className="text-muted" style={{ fontSize: "small" }}>
                  Enter your username to reset your password
                </p>
                {/* Conditionally render the spinner when loading is true */}
                {loading && <Spinner />}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="username"
                      placeholder="Email/Phone"
                      value={formData.username}
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#03045E",
                        color: "white",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
