import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const LatestBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [popularPosts, setPopularPosts] = useState([]);
  const [formData, setFormData] = useState({ name: "", email: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}MyLatestBlog`
        );
        const data = await response.json();

        if (data.status === "true") {
          setBlogs(data.data);
        } else {
          setBlogs([]); // Set empty array if no data is returned
        }
      } catch (error) {
        console.error("Error fetching latest blogs:", error);
        setBlogs([]); // Set empty array on error
      }
    };

    const fetchPopularPosts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}PopularPostBlog`
        );
        const data = await response.json();

        if (data.status === "true") {
          setPopularPosts(data.data);
        } else {
          setPopularPosts([]); // Set empty array if no data is returned
        }
      } catch (error) {
        console.error("Error fetching popular posts:", error);
        setPopularPosts([]); // Set empty array on error
      }
    };

    fetchData();
    fetchPopularPosts();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}Subscriber`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      console.log("Response --:", data);
      // Handle success or display a success message to the user
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error or display an error message to the user
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row text-center mx-3">
          <h3 className="mb-3 mt-5 text-primary fw-bold">My Latest Blog</h3>

          <div className="col-lg-8 ps-0 ps-md-5">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div
                  key={blog.Id}
                  className="card border-0 mt-5"
                  style={{
                    width: "100%",
                  }}
                >
                  <Link
                    to={`/BlogInner/${blog.slug}`}
                    className="card-link"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="row no-gutters m-0">
                      <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <img
                          className="w-100 object-fit-cover"
                          src={blog.Image}
                          alt={blog.Title}
                          style={{
                            borderRadius: "10px",
                            maxHeight: "200px",
                          }}
                        />
                      </div>
                      <div className="col-md-6 d-flex justify-content-center align-items-center text-start">
                        <div className="card-body">
                          <h5 className="card-text">
                            <b>{blog.Title}</b>
                          </h5>

                          <h6
                            className="card-title"
                            style={{ color: "#023E8A" }}
                          >
                            {blog.PostDate}
                          </h6>
                          <hr className="my-2" />
                          <span
                            className="card-text"
                            style={{ color: "#666666", fontSize: "14px" }}
                            dangerouslySetInnerHTML={{
                              __html: blog.ShortDescription,
                            }}
                          ></span>
                          <h6>
                            Posted by{" "}
                            <span style={{ color: "#FFA902" }}>
                              {blog.Post}
                            </span>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <h3>No blogs available</h3>
            )}
          </div>

          <div className="col-lg-4 px-0 px-md-5 mt-5">
            <div
              className="p-2 p-sm-4 w-100"
              style={{
                boxShadow:
                  "0px 1.5281014442443848px 15.281013488769531px 0px #0000001A",
                borderRadius: "15px",
              }}
            >
              <h4>
                <b>Popular Post</b>
              </h4>
              {popularPosts.map((post) => (
                <div
                  key={post.Id}
                  className="card border-0"
                  style={{
                    width: "100%",
                  }}
                >
                  <Link
                    to={`/BlogInner/${post.slug}`}
                    className="card-link"
                    style={{ textDecoration: "none" }}
                  >
                    <div className="m-0 row no-gutters">
                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <img
                          className="w-100 object-fit-cover"
                          src={post.Image}
                          alt={post.Title}
                          style={{
                            borderRadius: "5px",
                            maxHeight: "90px",
                          }}
                        />
                      </div>
                      <div className="col-md-8 d-flex justify-content-center align-items-center">
                        <div className="card-body text-start">
                          <h6
                            className="text-left card-title"
                            style={{ color: "#023E8A" }}
                          >
                            {post.Title}
                          </h6>
                          <hr className="my-2" />
                          <p className="text-left mb-0 card-text">
                            <small>
                              posted by{" "}
                              <span style={{ color: "#FFA902" }}>
                                {" "}
                                {post.Post}
                              </span>
                            </small>
                          </p>
                          <p
                            className="text-left mb-0"
                            style={{ color: "#666666", fontSize: "14px" }}
                          >
                            {post.PostDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div
              className="card mt-3"
              style={{
                boxShadow:
                  "0px 1.5281014442443848px 15.281013488769531px 0px #0000001A",
                border: "none",
              }}
            >
              <div className="card-body  p-4" style={{ borderRadius: "15px" }}>
                <h4 className="text-start w-100 card-title mb-3">
                  <b style={{ fontFamily: "sans-serif" }}>Follow Me</b>
                </h4>

                <h3
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <Link
                    to="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL"
                    className="me-3"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                  <Link
                    to="https://www.instagram.com/travellers_choice_ladakh/"
                    className="me-3"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link to="https://youtube.com/@TRAVELLERSCHOICE-ll2kl?si=EX6n-jYw7HA-1oK3">
                    <FontAwesomeIcon icon={faYoutube} />
                  </Link>
                </h3>
              </div>
            </div>

            <div
              className="card mt-3"
              style={{
                boxShadow:
                  "0px 1.5281014442443848px 15.281013488769531px 0px #0000001A",
                border: "none",
                backgroundColor: "#03045E",
                color: "#FFFFFF",
              }}
            >
              {/* <div className="card-body p-4" style={{
                boxShadow:
                  "0px 1.5281014442443848px 15.281013488769531px 0px #0000001A",
                border: "none",
                backgroundColor: "#03045E",
                color: "#FFFFFF",
              }}>
                <h4 className="text-start">Subscribe Today!</h4>
                <h6 className="text-start mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                  sed et donec purus viverra. Sit justo hello of fjdi hecsdh.
                </h6>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{
                      borderRadius: "13px",
                      margin: "10px 0",
                      padding: "8px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                    }}
                  />{" "}
                  <br />
                  <input
                    type="email"
                    className="w-100"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{
                      borderRadius: "13px",
                      margin: "10px 0",
                      padding: "8px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      style={{ marginRight: "5px" }}
                    />
                    <label
                      className="fw-medium"
                      htmlFor="privacyPolicy"
                      style={{ marginBottom: "0", color: "#FFFFFF" }}
                    >
                      You agree to our company privacy policy
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="w-100"
                    style={{
                      borderRadius: "8px",
                      padding: "5px 100px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <b>Subscribe</b>
                  </button>
                </form>
              </div> */}
              <div
                className="card-body p-4 "
                style={{
                  boxShadow:
                    "0px 1.5281014442443848px 15.281013488769531px 0px #0000001A",
                  borderRadius: "20px",
                  backgroundColor: "#03045E",
                  color: "#FFFFFF",
                }}
              >
                <h4 className="text-start">Subscribe Today!</h4>
                <h6 className="text-start mt-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                  sed et donec purus viverra. Sit justo hello of fjdi hecsdh.
                </h6>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className="w-100"
                    placeholder="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{
                      borderRadius: "13px",
                      margin: "10px 0",
                      padding: "8px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                    }}
                  />{" "}
                  <br />
                  <input
                    type="email"
                    className="w-100"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{
                      borderRadius: "13px",
                      margin: "10px 0",
                      padding: "8px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="privacyPolicy"
                      style={{ marginRight: "5px" }}
                    />
                    <label
                      className="fw-medium"
                      htmlFor="privacyPolicy"
                      style={{ marginBottom: "0", color: "#FFFFFF" }}
                    >
                      You agree to our company privacy policy
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="w-100"
                    style={{
                      borderRadius: "8px",
                      padding: "5px 100px",
                      backgroundColor: "#FFFFFF",
                      color: "#03045E",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <b>Subscribe</b>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlog;
