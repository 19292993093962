import React, { useState } from "react";
import LeftImage from "../../assets/Images/a1a1.png";
import { useNavigate, Link } from "react-router-dom";
import "./login.css";
import wlogo from "../../assets/Images/White_TCL_logo.png";

// Define the spinner component
const Spinner = () => <div className="loader" style={{ margin: "auto" }}></div>;

function SignUp() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailRegex.test(value);
    }

    if (name === "phone") {
      const phoneRegex = /^[0-9]+$/;
      isValid = phoneRegex.test(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "confirmPassword") {
      setPasswordsMatch(formData.password === value);
    }

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Invalid ${name}`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting the form

    if (formData.password !== formData.confirmPassword) {
      setPasswordsMatch(false);
      setLoading(false); // Reset loading state if passwords do not match
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}registration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        console.error("Registration failed:", response.statusText);
        setLoading(false); // Reset loading state if registration fails
        return;
      }

      const data = await response.json();
      if (
        data.status === "false" &&
        data.message === "The email has already been taken."
      ) {
        alert("The email has already been registered.");
        setLoading(false); // Reset loading state after handling the alert
        return;
      }

      const { token } = data;
      sessionStorage.setItem("token", token);
      navigate("/otp");
    } catch (error) {
      console.error("Error registering user:", error.message);
      alert("An error occurred while registering.");
    } finally {
      setLoading(false); // Set loading state to false after form submission or error handling
      setFormData({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      }); // Reset the form data
    }
  };

  return (
    <>
      {/* Conditionally render the spinner when loading is true */}
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      <div className="container-fluid">
        <div className="row m-0">
          <div className="col-lg-5 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100"
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "100% 100%",
                // webkitBackdropFilter: "blur(10px)",
              }}
            >
              <div
                className="ms-3"
                style={{ cursor: "pointer", color: "#FFFFFF" }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img src={wlogo} className="d-none d-md-block" width="185" alt="" />
            
              </div>
              
              <div className="h-75 d-flex justify-content-center align-items-center">
                <h4 style={{ color: "#FFFFFF" }}>
                  <b>LADAKH</b>
                </h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 "
            style={{ backgroundColor: "#03045E", height: "100vh" }}
          >
            {/* <img src={wlogo} className="d-block d-md-none" width="120" alt="" /> */}
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2 custom-card"
                style={{ maxheight: "550px", borderRadius: "20px" }}
              >
                <div className="card-body px-2 px-md-5 py-1 py-md-4">
                  <h2 className="card-title mt-3">SIGN UP</h2>
                  <p className="text-muted pb-3" style={{ fontSize: "medium" }}>
                    Join us and start your journey today - sign up in seconds!
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.phone && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                      {!passwordsMatch && (
                        <small className="text-danger">
                          Passwords do not match
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <p>
                        <input
                          type="checkbox"
                          name="agreed"
                          checked={true}
                          disabled
                          className="form-check-input"
                        />
                        &nbsp;I agree to the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#03045E",
                            fontWeight: "bold",
                          }}
                        >
                          Terms & Condition
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/privacy"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#03045E",
                            fontWeight: "bold",
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#03045E",
                          color: "white",
                        }}
                      >
                        Register{" "}
                      </button>
                    </div>
                  </form>
                  <p className="text-center mt-3 mb-3">
                    Already have an account?{" "}
                    <span className="text-center m-0">
                      <Link
                        to="/Login"
                        style={{
                          textDecoration: "none",
                          color: "#03045E",
                          fontWeight: "bold",
                        }}
                      >
                        Login Now
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
