// import React, { useRef } from 'react';
// import Nubra from "../../assets/Images/nubra.png";
// import Carousel from "react-multi-carousel";
// import innsbrg from "../../assets/Images/innsbrg.png";
// import faroe from "../../assets/Images/faroe.png";
// import hall from "../../assets/Images/hall.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
// faStar,
// faHotel,
// faCar,
// faPersonHiking,
// } from "@fortawesome/free-solid-svg-icons";

// const Destinations = () => {
//     const responsive = {
//         superLargeDesktop: {
//         breakpoint: { max: 4000, min: 3000 },
//         items: 5,
//         },
//         desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 4,
//         },
//         tablet: {
//         breakpoint: { max: 1024, min: 768 },
//         items: 3, // Adjusted to show at least 2.5 cards on tablet screens
//         },
//         mobile: {
//         breakpoint: { max: 768, min: 0 },
//         items: 1,
//         },
//     };
//     const carouselRef = useRef(null);
// return (
//     <div>

//     <div className="container my-5">
//         <div className="text-center mt-5 mb-4">
//         <h3>
//             <b>Our Destinations Ladakh</b>
//         </h3>
//         </div>

//         <Carousel
//         responsive={responsive}
//         className="pb-4"
//         ref={carouselRef}
//         infinite
//         >
//         <div
//             className="card border w-100 p-3 mx-3"
//             style={{
//             borderRadius: "19px",
//             }}
//         >
//             <img
//             src={innsbrg}
//             className="card-img-top rounded"
//             alt="innsbrg"
//             style={{
//                 borderRadius: "8.86px",
//             }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//             <h6
//                 className="card-title d-flex align-items-center justify-content-between"
//                 style={{ fontSize: "18px" }} // Adjust the font size as needed
//             >
//                 Innsbruck{" "}
//                 <span>
//                 <FontAwesomeIcon icon={faStar} />
//                 <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                 </span>{" "}
//                 {/* Reduced font size for rating */}
//                 </span>
//             </h6>
//             <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                 3 Days & 4 Nights
//             </p>{" "}
//             {/* Reduced font size */}
//             </div>
//             <div className="row">
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 1 Hotel
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 2 Transfers
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 4 Activities
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//             <ul
//                 style={{
//                 textAlign: "left",
//                 opacity: "0.5",
//                 fontSize: "13px", // Reduced font size for list items
//                 }}
//             >
//                 <li>Tour combo with return airport transfer</li>
//                 <li>City Tour</li>
//                 <li>Curious Corner</li>
//             </ul>
//             </div>
//         </div>

//         <div
//             className="card border w-100 p-3 mx-3"
//             style={{
//             borderRadius: "19px",
//             }}
//         >
//             <img
//             src={Nubra}
//             className="card-img-top rounded"
//             alt="Nubra"
//             style={{
//                 borderRadius: "8.86px",
//             }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//             <h6
//                 className="card-title d-flex align-items-center justify-content-between"
//                 style={{ fontSize: "18px" }} // Adjust the font size as needed
//             >
//                 Nubra Valley{" "}
//                 <span>
//                 <FontAwesomeIcon icon={faStar} />
//                 <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                 </span>{" "}
//                 {/* Reduced font size for rating */}
//                 </span>
//             </h6>
//             <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                 3 Days & 4 Nights
//             </p>{" "}
//             {/* Reduced font size */}
//             </div>
//             <div className="row">
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 1 Hotel
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 2 Transfers
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 4 Activities
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//             <ul
//                 style={{
//                 textAlign: "left",
//                 opacity: "0.5",
//                 fontSize: "13px", // Reduced font size for list items
//                 }}
//             >
//                 <li>Tour combo with return airport transfer</li>
//                 <li>City Tour</li>
//                 <li>Curious Corner</li>
//             </ul>
//             </div>
//         </div>

//         <div
//             className="card border w-100 p-3 mx-3"
//             style={{
//             borderRadius: "19px",
//             }}
//         >
//             <img
//             src={hall}
//             className="card-img-top rounded"
//             alt="hall"
//             style={{
//                 borderRadius: "8.86px",
//             }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//             <h6
//                 className="card-title d-flex align-items-center justify-content-between"
//                 style={{ fontSize: "18px" }} // Adjust the font size as needed
//             >
//                 Hallstatt{" "}
//                 <span>
//                 <FontAwesomeIcon icon={faStar} />
//                 <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                 </span>{" "}
//                 {/* Reduced font size for rating */}
//                 </span>
//             </h6>
//             <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                 3 Days & 4 Nights
//             </p>{" "}
//             {/* Reduced font size */}
//             </div>
//             <div className="row">
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 1 Hotel
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 2 Transfers
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 4 Activities
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//             <ul
//                 style={{
//                 textAlign: "left",
//                 opacity: "0.5",
//                 fontSize: "13px", // Reduced font size for list items
//                 }}
//             >
//                 <li>Tour combo with return airport transfer</li>
//                 <li>City Tour</li>
//                 <li>Curious Corner</li>
//             </ul>
//             </div>
//         </div>

//         <div
//             className="card border w-100 p-3 mx-3"
//             style={{
//             borderRadius: "19px",
//             }}
//         >
//             <img
//             src={faroe}
//             className="card-img-top rounded"
//             alt="faroe"
//             style={{
//                 borderRadius: "8.86px",
//             }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//             <h6
//                 className="card-title d-flex align-items-center justify-content-between"
//                 style={{ fontSize: "18px" }} // Adjust the font size as needed
//             >
//                 Faroe Island{" "}
//                 <span>
//                 <FontAwesomeIcon icon={faStar} />
//                 <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                 </span>{" "}
//                 {/* Reduced font size for rating */}
//                 </span>
//             </h6>
//             <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                 3 Days & 4 Nights
//             </p>{" "}
//             {/* Reduced font size */}
//             </div>
//             <div className="row">
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 1 Hotel
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 2 Transfers
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 4 Activities
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//             <ul
//                 style={{
//                 textAlign: "left",
//                 opacity: "0.5",
//                 fontSize: "13px", // Reduced font size for list items
//                 }}
//             >
//                 <li>Tour combo with return airport transfer</li>
//                 <li>City Tour</li>
//                 <li>Curious Corner</li>
//             </ul>
//             </div>
//         </div>

//         <div
//             className="card border w-100 p-3 mx-3"
//             style={{
//             borderRadius: "19px",
//             }}
//         >
//             <img
//             src={hall}
//             className="card-img-top rounded"
//             alt="hall"
//             style={{
//                 borderRadius: "8.86px",
//             }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//             <h6
//                 className="card-title d-flex align-items-center justify-content-between"
//                 style={{ fontSize: "18px" }} // Adjust the font size as needed
//             >
//                 Hallstatt{" "}
//                 <span>
//                 <FontAwesomeIcon icon={faStar} />
//                 <span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                 </span>{" "}
//                 {/* Reduced font size for rating */}
//                 </span>
//             </h6>
//             <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                 3 Days & 4 Nights
//             </p>{" "}
//             {/* Reduced font size */}
//             </div>
//             <div className="row">
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 1 Hotel
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 2 Transfers
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             <div
//                 className="col-4 d-flex flex-column align-items-center"
//                 style={{ opacity: "0.5" }}
//             >
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
//                 4 Activities
//                 </span>{" "}
//                 {/* Reduced font size */}
//             </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//             <ul
//                 style={{
//                 textAlign: "left",
//                 opacity: "0.5",
//                 fontSize: "13px", // Reduced font size for list items
//                 }}
//             >
//                 <li>Tour combo with return airport transfer</li>
//                 <li>City Tour</li>
//                 <li>Curious Corner</li>
//             </ul>
//             </div>
//         </div>
//         </Carousel>

//         <p className="text-center">
//         <b>See All</b>
//         </p>
//     </div>

//     </div>
// )
// }

// export default Destinations

// import React, { useState, useEffect, useRef } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar, faHotel, faCar, faPersonHiking } from "@fortawesome/free-solid-svg-icons";
// import { NavLink } from "react-router-dom";

// const Destinations = () => {
//   const [destinations, setDestinations] = useState([]);
//   const carouselRef = useRef(null);

//   // Fetch data from API
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}AllDestination`);
//         const data = await response.json();
//         if (data.status === "true") {
//           setDestinations(data.data);
//         } else {
//           console.error("Error fetching data:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   // Responsive settings for carousel
//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 768 },
//       items: 3,
//     },
//     mobile: {
//       breakpoint: { max: 768, min: 0 },
//       items: 1,
//     },
//   };

//   return (
//     <div className="container my-5">
//       <div className="text-center mt-5 mb-4">
//         <h3>
//           <b>Our Destinations Ladakh</b>
//         </h3>
//       </div>

//       <Carousel responsive={responsive} className="pb-4" ref={carouselRef} infinite>
//         {destinations.map((destination) => (
//           {/* <div key={destination.id} className="card border w-100 p-3 mx-3" style={{ borderRadius: "19px" }}>
//             <img
//               src={destination.Image}
//               className="card-img-top rounded border"
//               alt={destination.Name}
//               style={{ borderRadius: "8.86px", height:"200px", objectFit:"cover" }}
//             />
//             <div className="card-body" style={{ gap: "26.57px" }}>
//               <h6 className="card-title d-flex align-items-center justify-content-between" style={{ fontSize: "18px" }}>
//                 {destination.Name}{" "}
//                 <span>
//                   <FontAwesomeIcon icon={faStar} />
//                   <span style={{ fontSize: "14px", marginLeft: "5px" }}>4.9</span>
//                 </span>
//               </h6>
//               <p style={{ opacity: "0.5", fontSize: "14px" }}>{destination.Day} Days & {destination.Night} Nights</p>
//             </div>
//             <div className="row">
//               <div className="col-4 d-flex flex-column align-items-center" style={{ opacity: "0.5" }}>
//                 <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>1 Hotel</span>
//               </div>
//               <div className="col-4 d-flex flex-column align-items-center" style={{ opacity: "0.5" }}>
//                 <FontAwesomeIcon icon={faCar} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>2 Transfers</span>
//               </div>
//               <div className="col-4 d-flex flex-column align-items-center" style={{ opacity: "0.5" }}>
//                 <FontAwesomeIcon icon={faPersonHiking} />
//                 <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>4 Activities</span>
//               </div>
//             </div>
//             <div className="card-body pb-0 px-0">
//               <ul style={{ textAlign: "left", opacity: "0.5", fontSize: "13px" }}>
//                 {destination.Activities.map((activity, index) => (
//                   <li key={index}>{activity[1]}</li>
//                 ))}
//               </ul>
//             </div>
//           </div> */}
//           <div key={rowIndex} className="row mt-2">
//                 {row.map((destination) => (
//                   <div
//                     key={destination.id}
//                     className="col-lg-3 col-md-6 pe-4 mb-4"
//                   >
//                     <div
//                       className="card border-0 rounded"
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
//                     >
//                       <img
//                         src={destination.Image}
//                         className="card-img-top rounded"
//                         style={{ height: "250px", objectFit: "cover" }}
//                         alt={destination.Name}
//                       />
//                       <div className="card-body">
//                         <h6
//                           className="card-title d-flex align-items-center justify-content-between"
//                           style={{ fontSize: "18px" }}
//                         >
//                           {destination.Name}{" "}
//                           <span>
//                             <FontAwesomeIcon icon={faStar} />
//                             <span
//                               style={{ fontSize: "14px", marginLeft: "5px" }}
//                             >
//                               {destination.Rating}
//                             </span>
//                           </span>
//                         </h6>
//                         <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                           {destination.Day} Days & {destination.Night} Nights
//                         </p>
//                         <div className="row">
//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon
//                               icon={faHotel}
//                               style={{ color: "#000000" }}
//                             />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[0]} Hotels
//                             </span>
//                           </div>

//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon icon={faCar} />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[1]} Transfers
//                             </span>
//                           </div>

//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon icon={faPersonHiking} />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[2]} Activities
//                             </span>
//                           </div>
//                         </div>
//                         <ul
//                           className="mt-2"
//                           style={{
//                             textAlign: "left",
//                             opacity: "0.5",
//                             fontSize: "13px",
//                           }}
//                         >
//                           <li
//                             dangerouslySetInnerHTML={{
//                               __html: destination.Details,
//                             }}
//                           ></li>
//                         </ul>

//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//         ))}
//       </Carousel>

//       <div className="text-center">
//       <NavLink to="/LadakhDest" style={{ textDecoration: "none" }}>
//         <b>See All</b>
//       </NavLink>
//     </div>
//     </div>
//   );
// };

// export default Destinations;

import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faHotel,
  faCar,
  faPersonHiking,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Destinations = () => {
  const [destinations, setDestinations] = useState([]);
  const carouselRef = useRef(null);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllDestination`
        );
        const data = await response.json();
        if (data.status === "true") {
          setDestinations(data.data);
        } else {
          console.error("Error fetching data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Responsive settings for carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container my-5">
      <div className="text-center mt-5 mb-4">
        <h3 className="text-primary">
          <b>OUR DESTINATIONS LADAKH</b>
        </h3>
      </div>

      <Carousel
        responsive={responsive}
        className="pb-4"
        ref={carouselRef}
        infinite
      >
        {destinations.map((destination) => (
          <div
            key={destination.id}
            className="card border w-100 p-3 mx-3 h-100"
            style={{ borderRadius: "19px" }}
          >
            <img
              src={destination.Image}
              className="card-img-top rounded border"
              alt={destination.Name}
              style={{
                borderRadius: "8.86px",
                height: "200px",
                objectFit: "cover",
              }}
            />
            <div className="card-body" style={{ gap: "26.57px" }}>
              <h6
                className="card-title d-flex align-items-center justify-content-between"
                style={{ fontSize: "18px" }}
              >
                {destination.Name}{" "}
                <span>
                  <FontAwesomeIcon style={{color:"#ffd049"}} icon={faStar} />
                  <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                    4.9
                  </span>
                </span>
              </h6>
              {/* <p style={{ opacity: "0.5", fontSize: "14px" }}>
                {destination.Day} Days & {destination.Night} Nights
              </p> */}
            </div>
            <div className="row">
              <div
                className="col-4 d-flex flex-column align-items-center"
                style={{ opacity: "0.5" }}
              >
                <FontAwesomeIcon icon={faHotel} style={{ color: "#000000" }} />
                <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                  {" "}
                  {destination.Activities[0]} Hotel
                </span>
              </div>
              <div
                className="col-4 d-flex flex-column align-items-center"
                style={{ opacity: "0.5" }}
              >
                <FontAwesomeIcon icon={faCar} />
                <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                  {" "}
                  {destination.Activities[1]} Transfers
                </span>
              </div>
              <div
                className="col-4 d-flex flex-column align-items-center"
                style={{ opacity: "0.5" }}
              >
                <FontAwesomeIcon icon={faPersonHiking} />
                <span style={{ marginTop: "0.5rem", fontSize: "12px" }}>
                  {" "}
                  {destination.Activities[2]} Activities
                </span>
              </div>
            </div>
            <div className="card-body pb-0 px-0">
              <div style={{fontSize:"13px"}} dangerouslySetInnerHTML={{
                    __html: destination.Details,
                  }}>

              </div>
            </div>
          </div>
        ))}
      </Carousel>

      <div className="text-center">
        <NavLink to="/LadakhDest" style={{ textDecoration: "none" }}>
          <b>See All</b>
        </NavLink>
      </div>
    </div>
  );
};

export default Destinations;
