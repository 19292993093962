import React from "react";
import ProfileNav from "./ProfileNav";
import "./ProfilePage1.css";
import MyProfile from "./MyProfile";
import Bookings from "./Bookings";
import Quotations from "./Quotations";
import Transactions from "./Transactions";
import Nav from "../HomePage/Nav";

const ProfilePage1 = () => {
  console.log("token--",sessionStorage.getItem("token"));
  return (
    <>
      <div className="container-fluid">
        {/* <ProfileNav /> */}
        <Nav/>
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center profile-container">
            <ul class="w-100 nav nav-tabs gap-2" style={{flexDirection : "column"}} id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active text-start fw-semibold w-100"
                  style={{ fontSize : "20px" }}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  My Profile
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-start fw-semibold w-100"
                  style={{ fontSize : "20px" }}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Booking"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  My Bookings
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-start fw-semibold w-100"
                  style={{ fontSize : "20px" }}
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Quotations"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  My Quotation
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-start fw-semibold w-100"
                  style={{ fontSize : "20px" }}
                  id="transaction-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Transactions"
                  type="button"
                  role="tab"
                  aria-controls="transaction"
                  aria-selected="false"
                >
                   My Transaction
                </button>
              </li>
            </ul>
          </div>

          <div className="col-lg-8 mt-5">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
              <MyProfile/>
                
              </div>

              <div
                class="tab-pane fade"
                id="Booking"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Bookings/>
              </div>

              <div
                class="tab-pane fade"
                id="Quotations"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <Quotations />
              </div>

              <div
                class="tab-pane fade"
                id="Transactions"
                role="tabpanel"
                aria-labelledby="transaction-tab"
              >
                <Transactions/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage1;
