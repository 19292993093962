import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FeaturedExplore = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}FeaturedExploreBlog`
        );
        const data = await response.json();
        console.log("data",data);
        if (data.status === "true") {
          setCards(data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to chunk the array into smaller arrays
  const chunkArray = (array, size) => {
    // console.log('Array before chunking:', array); // Check the value of the array before chunking
    if (!Array.isArray(array)) {
      console.error("Invalid array:", array);
      return [];
    }

    return array.reduce((acc, _, index) => {
      if (index % size === 0) {
        acc.push(array.slice(index, index + size));
      }
      return acc;
    }, []);
  };

  const chunkedCards = chunkArray(cards, 4); // Chunk cards into groups of 4
  //console.log('Chunked cards--:', chunkedCards); // Check the chunked cards array

  // Rest of your FeaturedExplore component code...

  return (
    <div>
      <div className="row mx-3 mx-md-5 text-center justify-content-center">
        <h3 className="mb-3 text-primary fw-bold mb-5">Featured Explore</h3>
        <div id="carouselExampleIndicators" className="carousel slide carousel">
          <div className="carousel-inner">
            {chunkedCards.map((chunk, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <div className="row m-0">
                  {chunk.map((card) => (
                    <div key={card.Id} className="col-md-3 pe-0 pe-md-4">
                      <Link
                        to={`/BlogInner/${card.slug}`}
                        className="card-link"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="card">
                          <img
                            src={card.Image}
                            className="card-img-top"
                            style={{ height: "250px", objectFit: "cover" }}
                            alt={card.Title}
                          />
                          <div className="card-body">
                            <h5 className="card-title">{card.Title}</h5>
                            {/* <p className="card-text">{card.ShortDescription}</p> */}
                            <p
                              className="card-text"
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: card.ShortDescription,
                              }}
                            ></p>
                            <p
                              className="card-text"
                              style={{ fontSize: "12px" }}
                            >
                              <span style={{ color: "#FFA902" }}>
                                {" "}
                                solo travel{" "}
                              </span>{" "}
                              by {card.Post}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev d-none d-md-block"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next d-none d-md-block"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedExplore;
