// import React, { useState, useEffect, useRef } from "react";
// import Nav from "./BlueNav";
// import { Navigate, useParams } from "react-router-dom";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faTwitter,
//   faLinkedin,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";
// import {
//   faStar,
//   faHotel,
//   faCar,
//   faPersonHiking,
//   faTag,
//   faSun,
//   faLocation,
//   faLocationPin,
//   faCalendar,
//   faLocationDot,
//   faClock,
// } from "@fortawesome/free-solid-svg-icons";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import "./PackageDestination.css";

// const PackageDestination = () => {
//   const { id } = useParams();
//   const { slug } = useParams();
//   const [selectedPackage, setSelectedPackage] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [currentDetail, setCurrentDetail] = useState("overview"); // Default detail to show
//   const [destinations, setDestinations] = useState([]);
//   const [paymentDetails, setPaymentDetails] = useState("");
//   const [activeItem, setActiveItem] = useState(null); // State to track active accordion item
//   const [packageId, setPackageId] = useState("");
//   const [isOverviewActive, setIsOverviewActive] = useState(true);
//   const [widgetData, setWidgetData] = useState(null); // State to hold widget data
//   const [showWidget, setShowWidget] = useState(false); // Default to false

//   const nameRef = useRef(null);
//   const emailRef = useRef(null);
//   const whereToGoRef = useRef(null);
//   const countryCodeRef = useRef(null);
//   const phoneNoRef = useRef(null);
//   const messageRef = useRef(null);
//   const getQuoteButtonRef = useRef(null);

//   const fetchPackageData = async () => {
//     setLoading(true);
//     try {
//       const slug = sessionStorage.getItem("slugg");
//      // console.log("slud",slug);
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}AllPackages`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const responseData = await response.json();
//       const packageData = responseData.data.find(
//         (item) => item.slug === slug
//       );

//       // console.log("response Data-", responseData);
//       // console.log("packageData - ", packageData);
//       const abc = packageData.category.id;
//       // console.log("idd--", abc);
//       //   console.log("useparanskg",slug);

//       setSelectedPackage(packageData);
//       setPaymentDetails(packageData ? packageData.PaymentDetails : "");
//       setPackageId(packageData ? packageData.id : ""); // Set the package ID

//       // Check if "Visit" data contains widget information
//       if (packageData && packageData.Visit && packageData.Visit.length > 0) {
//         setWidgetData(packageData.Visit[0]); // Set widget data
//         setShowWidget(true); // Show the widget
//       } else {
//         setShowWidget(false); // Hide the widget if data is not available
//       }
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // added -->
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = {
//       Name: nameRef.current.value,
//       Email: emailRef.current.value,
//       Phone: phoneNoRef.current.value,
//       WhereToGo: whereToGoRef.current.value,
//       Message: messageRef.current.value,
//       PackageId: packageId, // Include the package ID in the form data
//     };
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}PackageQoutes`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to submit form data");
//       }
//       const responseData = await response.json();
//       console.log("Form submission successful:", responseData);
//       // Reset form fields if needed
//       nameRef.current.value = "";
//       emailRef.current.value = "";
//       phoneNoRef.current.value = "";
//       whereToGoRef.current.value = "";
//       messageRef.current.value = "";
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   //--->

//   // Fetch data from API

//   // ------------------->> All  Destinations

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}AllDestination`
//         );
//         const data = await response.json();
//         if (data.status === "true") {
//           setDestinations(data.data);
//         } else {
//           console.error("Error fetching data:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   // ------------------->> All  Destinations
//   // Function to chunk destinations into groups of 4
//   const chunkArray = (array, size) => {
//     const chunkedArray = [];
//     for (let i = 0; i < array.length; i += size) {
//       chunkedArray.push(array.slice(i, i + size));
//     }
//     return chunkedArray;
//   };
//   const handleButtonClick = (detail) => {
//     setCurrentDetail(detail);
//     setIsOverviewActive(detail === "overview"); // Update isOverviewActive based on the clicked button
//     fetchPackageData(detail);
//   };

//   useEffect(() => {
//     fetchPackageData(currentDetail);
//   }, [id, currentDetail]);

//   const handleGetQuoteClick = () => {
//     nameRef.current.focus();
//   };

//   const handleAccordionClick = (index) => {
//     setActiveItem(activeItem === index ? null : index); // Toggle active item
//   };
  

//   useEffect(() => {
//     // Load the widget script dynamically here based on widgetData
//     if (showWidget && widgetData) {
//       const script = document.createElement("script");
//       script.src = widgetData.scriptUrl; // Assuming widgetData has a scriptUrl field
//       script.async = true;
//       document.body.appendChild(script);

//       return () => {
//         document.body.removeChild(script);
//       };
//     }
//   }, [showWidget, widgetData]);


//   const handleBookOnline = () => {
//     sessionStorage.setItem("slugg", slug);
//      const categoryId = selectedPackage ? selectedPackage.category.id : null;
//      console.log("IDDD-",categoryId);
//     if (categoryId === 3) {
//       window.location.href = "/BookOnline";
//     } else {
//       window.location.href = "/BookOnlineCarHotel";
//     }
//   };


//   return (
//     <>
//       <div className="container p-0">
//         <Nav />
//         <h3 className="text-center mt-5 pt-0 pt-md-5">
//           <b>{selectedPackage && selectedPackage.name}</b>
//         </h3>

//         <div className="row mt-5 mx-3">
//           <div className="col-lg-8">
//             {loading && <p>Loading...</p>}
//             {error && <p>Error: {error}</p>}
//             {selectedPackage && (
//               <>
//                 <div
//                   className="custom-carousel-container"
//                   style={{ borderRadius: "10px", overflow: "hidden" }}
//                 >
//                   <div
//                     id="carouselExampleInterval"
//                     className="carousel slide"
//                     data-ride="carousel"
//                   >
//                     <div className="carousel-inner">
//                       {selectedPackage.image.map((img, index) => (
//                         <div
//                           key={index}
//                           className={`carousel-item ${
//                             index === 0 ? "active" : ""
//                           }`}
//                           data-interval="10000"
//                         >
//                           <img
//                             src={`${img}`}
//                             className="d-block w-100"
//                             style={{ maxHeight: "400px", objectFit: "cover" }}
//                             alt={`Slide ${index}`}
//                           />
//                         </div>
//                       ))}
//                     </div>
//                     <a
//                       className="carousel-control-prev"
//                       href="#carouselExampleInterval"
//                       role="button"
//                       data-slide="prev"
//                     >
//                       <span
//                         className="carousel-control-prev-icon"
//                         aria-hidden="true"
//                       ></span>
//                       <span className="sr-only">Previous</span>
//                     </a>
//                     <a
//                       className="carousel-control-next"
//                       href="#carouselExampleInterval"
//                       role="button"
//                       data-slide="next"
//                     >
//                       <span
//                         className="carousel-control-next-icon"
//                         aria-hidden="true"
//                       ></span>
//                       <span className="sr-only">Next</span>
//                     </a>
//                   </div>
//                 </div>

//                 <div className="d-flex gap-4 flex-wrap mt-5 mb-3">
//                   <button
//                     className={`btn custom-btn ${
//                       currentDetail === "overview" ? "active" : ""
//                     }`}
//                     onClick={() => handleButtonClick("overview")}
//                     style={{
//                       backgroundColor:
//                         currentDetail === "overview" ? "#03045E" : "",
//                       color: currentDetail === "overview" ? "#FFFFFF" : "",
//                     }}
//                   >
//                     Overview
//                   </button>
//                   {selectedPackage.category.heading === "Hotel Bookings" ? (
//                     <button
//                       className={`btn custom-btn ${
//                         currentDetail === "hotel_highlight" ? "active" : ""
//                       }`}
//                       onClick={() => handleButtonClick("hotel_highlight")}
//                       style={{
//                         backgroundColor:
//                           currentDetail === "hotel_highlight" ? "#03045E" : "",
//                         color:
//                           currentDetail === "hotel_highlight" ? "#FFFFFF" : "",
//                       }}
//                     >
//                       Hotel Highlight
//                     </button>
//                   ) : (
//                     <button
//                       className={`btn custom-btn ${
//                         currentDetail === "trip_highlight" ? "active" : ""
//                       }`}
//                       onClick={() => handleButtonClick("trip_highlight")}
//                       style={{
//                         backgroundColor:
//                           currentDetail === "trip_highlight" ? "#03045E" : "",
//                         color:
//                           currentDetail === "trip_highlight" ? "#FFFFFF" : "",
//                       }}
//                     >
//                       Trip Highlight
//                     </button>
//                   )}

//                   {selectedPackage.category.heading !== "Hotel Bookings" && (
//                     <button
//                       className={`btn custom-btn ${
//                         currentDetail === "itinerary_details" ? "active" : ""
//                       }`}
//                       onClick={() => handleButtonClick("itinerary_details")}
//                       style={{
//                         backgroundColor:
//                           currentDetail === "itinerary_details"
//                             ? "#03045E"
//                             : "",
//                         color:
//                           currentDetail === "itinerary_details"
//                             ? "#FFFFFF"
//                             : "",
//                       }}
//                     >
//                       Itinerary Detail
//                     </button>
//                   )}

//                   <button
//                     ref={getQuoteButtonRef}
//                     className={`btn custom-btn ${
//                       currentDetail === "get_quote" ? "active" : ""
//                     }`}
//                     onClick={() => handleButtonClick("get_quote")}
//                     style={{
//                       backgroundColor:
//                         currentDetail === "get_quote" ? "#03045E" : "",
//                       color: currentDetail === "get_quote" ? "#FFFFFF" : "",
//                     }}
//                   >
//                     Get Quote
//                   </button>
//                 </div>

//                 <div
//                   className="align-items-center p-2 mb-2"
//                   style={{
//                     borderStyle: "dotted",
//                     borderRadius: "15px",
//                     borderColor: "#CAF0F8",
//                   }}
//                 >
//                   <div className="d-inline-flex align-items-center p-2">
//                     <FontAwesomeIcon
//                       icon={faLocationDot}
//                       style={{ marginRight: "8px" }}
//                     />
//                     <span style={{ color: "#03045E" }}>
//                       <h5 className="mb-0">
//                         <b>Destination Covered :</b>
//                       </h5>
//                     </span>
//                   </div>
//                   <div
//                     className="align-items-center"
//                     style={{ color: "#666666", paddingLeft: "30px" }}
//                   >
//                     <b>{selectedPackage && selectedPackage.location}</b>
//                   </div>
//                   <div className="ms-2">
//                     <div className="d-flex align-items-center">
//                       <FontAwesomeIcon icon={faCalendar} />{" "}
//                       <span className="ms-2" style={{ color: "black" }}>
//                         <h5 style={{ color: "blue", marginTop: "10px" }}>
//                           <b style={{ color: "#03045E" }}>
//                             Available Tour Dates
//                           </b>
//                         </h5>
//                       </span>
//                     </div>
//                     <div className="d-flex flex-wrap">
//                       {selectedPackage && selectedPackage.category.id === 3 ? (
//                         selectedPackage.TourDate.map((date, index) => (
//                           <p
//                             key={index}
//                             style={{ color: "#666666", paddingLeft: "20px" }}
//                           >
//                             <b>{date}</b>
//                           </p>
//                         ))
//                       ) : (
//                         <p style={{ color: "#666666", paddingLeft: "20px" }}>
//                           <b>
//                             You can select your Dates at the time of Booking
//                           </b>
//                         </p>
//                       )}
//                     </div>
//                   </div>
//                 </div>

//                 {/* ------------ Destination Covered end -------*/}

//                 <div className="details-container">
//                   {currentDetail === "overview" && (
//                     <div
//                       dangerouslySetInnerHTML={{
//                         __html: selectedPackage.overview,
//                       }}
//                     ></div>
//                   )}

//                   {/* Trip Highlight */}
//                   {currentDetail === "trip_highlight" && (
//                     <div>
//                       {selectedPackage.trip_highlight.map(
//                         (highlight, index) => (
//                           <div
//                             key={index}
//                             className="d-flex align-items-center mb-3 p-3"
//                             style={{
//                               marginTop: "8px",
//                               border: "1.35px solid #0000001A",
//                               boxShadow: "1px 1px 2px 0px #0000001A",
//                               borderRadius: "8px",
//                             }}
//                           >
//                             <div className="me-2">
//                               <FontAwesomeIcon icon={faTag} />
//                             </div>
//                             <div>{highlight}</div>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   )}

//                   {currentDetail === "hotel_highlight" &&
//                     selectedPackage &&
//                     selectedPackage.trip_highlight && (
//                       <div>
//                         {selectedPackage.trip_highlight.map(
//                           (highlight, index) => (
//                             <div
//                               key={index}
//                               className="d-flex align-items-center mb-3 p-3"
//                               style={{
//                                 marginTop: "8px",
//                                 border: "1.35px solid #0000001A",
//                                 boxShadow: "1px 1px 2px 0px #0000001A",
//                                 borderRadius: "8px",
//                               }}
//                             >
//                               <div className="me-2">
//                                 <FontAwesomeIcon icon={faHotel} />
//                               </div>
//                               <div>{highlight}</div>
//                             </div>
//                           )
//                         )}
//                       </div>
//                     )}

//                   {/* --------------> */}

//                   {currentDetail === "itinerary_details" &&
//                     selectedPackage.intinerary_details && (
//                       <div id="accordionItinerary" className="accordion">
//                         {selectedPackage.intinerary_details.map(
//                           (detailsArray, outerIndex) => (
//                             <div
//                               className="accordion-item mb-3"
//                               key={outerIndex}
//                               style={{
//                                 backgroundColor: "#EFF1F6",
//                               }}
//                             >
//                               {detailsArray && detailsArray[0] && (
//                                 <div key={outerIndex} className="row">
//                                   {/* Simple button */}
//                                   <div className="col-2">
//                                     <button
//                                       className="btn btn-primary"
//                                       style={{
//                                         width: "100%",
//                                         height: "52px",
//                                         color: "#FFFFFF",
//                                         border: "none",
//                                       }}
//                                     >
//                                       <FontAwesomeIcon icon={faSun} /> Day{" "}
//                                       {outerIndex + 1}
//                                     </button>
//                                   </div>
//                                   {/* Accordion header and collapse */}
//                                   <div className="col-10">
//                                     <h2
//                                       className="accordion-header"
//                                       id={`headingItinerary${outerIndex}`}
//                                     >
//                                       <button
//                                         className={`accordion-button ${
//                                           activeItem === outerIndex
//                                             ? "active"
//                                             : ""
//                                         }`} // Apply "active" class when item is active
//                                         type="button"
//                                         onClick={() =>
//                                           handleAccordionClick(outerIndex)
//                                         } // Handle click event
//                                         aria-expanded={
//                                           activeItem === outerIndex
//                                             ? "true"
//                                             : "false"
//                                         }
//                                         aria-controls={`collapseItinerary${outerIndex}`}
//                                       >
//                                         {detailsArray[0]}
//                                       </button>
//                                     </h2>
//                                     <div
//                                       id={`collapseItinerary${outerIndex}`}
//                                       className={`accordion-collapse collapse ${
//                                         activeItem === outerIndex ? "show" : ""
//                                       }`} // Apply "show" class when item is active
//                                       aria-labelledby={`headingItinerary${outerIndex}`}
//                                       data-bs-parent="#accordionItinerary"
//                                     >
//                                       <div className="accordion-body">
//                                         <p>{detailsArray[1]}</p>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               )}
//                             </div>
//                           )
//                         )}
//                       </div>
//                     )}

//                   {/* ----------------> */}
//                 </div>
//               </>
//             )}
//           </div>

//           <div className="col-lg-4 ps-0 ps-md-4">
//             {/* PaymentDetails Card */}
//             <div
//               className="card"
//               style={{
//                 boxShadow:
//                   "-1.4294641017913818px 1.4294641017913818px 14.29464054107666px 7.14732027053833px #00000014",
//                 border: "1px solid #00000008",
//                 borderStyle: "none",
//               }}
//             >
//               <div className="card-body">
//                 <div className="d-flex gap-2 align-items-center">
//                   <span className="card-title text-primary h4 fw-bold text-left w-75">
//                     {selectedPackage && selectedPackage.name}{" "}
//                   </span>

//                   {selectedPackage && selectedPackage.category_id !== 1 && (
//                     <span
//                       className="text-white p-2 rounded"
//                       style={{ fontSize: "15px", background: "#03045E" }}
//                     >
//                       <FontAwesomeIcon icon={faSun} />{" "}
//                       {selectedPackage && selectedPackage.days} Days Tour
//                     </span>
//                   )}
//                 </div>
//                 <hr />
//                 <div
//                   className="card-text"
//                   dangerouslySetInnerHTML={{ __html: paymentDetails }}
//                 ></div>

//                 <h4 style={{ color: "#03045E" }}>
//                   Rs.{" "}
//                   <b>
//                     {selectedPackage &&
//                       (selectedPackage.category_id === 1
//                         ? selectedPackage.price[0]
//                         : selectedPackage.price)}
//                     /-
//                   </b>{" "}
//                   {/* <small>
//     {selectedPackage && selectedPackage.category_id === 1
//       ? "Per Night "
//       : "Per Person"}
//   </small> */}
//                   <small>
//                     {selectedPackage && selectedPackage.category_id === 1 ? (
//                       <>
//                         {" "}
//                         Night{" "}
//                         <span style={{ fontSize: "0.8em" }}>
//                           (Double Sharing)
//                         </span>{" "}
//                         <br></br>
//                         <small style={{ fontSize: "0.7em", color: "red" }}>
//                           (*food Included*)
//                         </small>
//                       </>
//                     ) : (
//                       "Per Person"
//                     )}
//                   </small>
//                 </h4>
//               </div>
//             </div>
//             {/* Buttonn Book Online */}

//             <div className="mt-4" style={{ borderRadius: "57.03px" }}>
//               <Link
//                 to="#"
//                 className="btn w-100 btn-primary"
//                 style={{ borderRadius: "50px" }}
//                 onClick={handleBookOnline}
//               >
//                 Book Online
//               </Link>
//             </div>

//             {/* Share This Trip */}

//             <div className="mt-5 text-center" style={{ color: "#03045E" }}>
//               <h5>Share This Trip</h5>
//               <div>
//                 <a
//                   href="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <FontAwesomeIcon className="me-2 h4" icon={faFacebook} />
//                 </a>

//                 <a
//                   href="https://www.instagram.com/travellers_choice_ladakh/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <FontAwesomeIcon className="me-2 h4" icon={faInstagram} />
//                 </a>

//                 <a
//                   href="https://www.linkedin.com/in/your-linkedin-url"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <FontAwesomeIcon className="me-2 h4" icon={faLinkedin} />
//                 </a>
//               </div>
//             </div>

//             {/* Enquiry */}
//             <div
//               className="card mt-5"
//               style={{
//                 boxShadow:
//                   "-1.3534338474273682px 1.3534338474273682px 13.534337997436523px 6.767168998718262px rgba(219, 226, 247, 0.55)",
//                 borderRadius: "20px",
//                 color: "#03045E",
//                 borderStyle: "none",
//               }}
//             >
//               <div className="card-body text-center">
//                 <h4 className="card-title">
//                   <b>Enquiry</b>
//                 </h4>
//                 <h6 className="card-subtitle mb-2 ">
//                   Want to know more contact us
//                 </h6>
//                 <h5>
//                   <FontAwesomeIcon icon={faPhone} />{" "}
//                   <a
//                     style={{ color: "#03045E", textDecoration: "none" }}
//                     href="tel:+91 97977 16377"
//                   >
//                     +91 97977 16377
//                   </a>
//                 </h5>
//               </div>
//             </div>

//             {/* Form */}
//             <div
//               className="card mt-3"
//               style={{
//                 backgroundColor: "#F6F7FC",
//                 boxShadow:
//                   "-1.3534338474273682px 1.3534338474273682px 13.534337997436523px 6.767168998718262px rgba(219, 226, 247, 0.55)",
//                 borderRadius: "20px",
//                 padding: "20px",
//                 borderStyle: "none",
//               }}
//             >
//               <div className="card-body text-center">
//                 <p>
//                   <span style={{ color: "red" }}>*</span>Leave a message and
//                   We'll get back to you
//                 </p>
//                 <form>
//                   <div className="mb-3">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Enter your Name"
//                       ref={nameRef}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="email"
//                       className="form-control"
//                       placeholder="Email Address"
//                       ref={emailRef}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Where to go?"
//                       ref={whereToGoRef}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Country Code"
//                       ref={countryCodeRef}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <input
//                       type="tel"
//                       className="form-control"
//                       placeholder="Phone No"
//                       ref={phoneNoRef}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <textarea
//                       className="form-control"
//                       rows="3"
//                       placeholder="Write a message"
//                       ref={messageRef}
//                     ></textarea>
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-primary w-100"
//                     onClick={handleSubmit}
//                   >
//                     <b>Submit</b>
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Adding Widgets */}

//         <div>
//           {showWidget && widgetData && (
//             <div>
//               <h3 className="my-4" style={{ color: "#03045E" }}>
//                 <b>Explore More</b>
//               </h3>
//               {/* Render the widget container based on widgetData */}
//               <div dangerouslySetInnerHTML={{ __html: widgetData.html }} />
//             </div>
//           )}
//         </div>
//         {/* Ladakh Tour Package */}

//         <div className="col-12">
//           <h3 className="mt-5 mb-3">Trending Ladakh Tour Packages</h3>
//           <div>
//             {/* Render cards in rows */}
//             {chunkArray(destinations, 4).map((row, rowIndex) => (
//               <div key={rowIndex} className="row mt-2">
//                 {row.map((destination) => (
//                   <div
//                     key={destination.id}
//                     className="col-lg-3 col-md-6 pe-4 mb-4"
//                   >
//                     <div
//                       className="card border-0 rounded"
//                       style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
//                     >
//                       <img
//                         src={destination.Image}
//                         className="card-img-top rounded"
//                         style={{ height: "250px", objectFit: "cover" }}
//                         alt={destination.Name}
//                       />
//                       <div className="card-body">
//                         <h6
//                           className="card-title d-flex align-items-center justify-content-between"
//                           style={{ fontSize: "18px" }}
//                         >
//                           {destination.Name}{" "}
//                           <span>
//                             <FontAwesomeIcon style={{color:"#ffd049"}} icon={faStar} />
//                             <span
//                               style={{ fontSize: "14px", marginLeft: "5px" }}
//                             >
//                               {destination.Rating}<span style={{ fontSize: "14px", marginLeft: "5px" }}>
//                     4.9
//                   </span>
//                             </span>
//                           </span>
//                         </h6>
//                         <p style={{ opacity: "0.5", fontSize: "14px" }}>
//                           {destination.Day} Days & {destination.Night} Nights
//                         </p>
//                         <div className="row">
//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon
//                               icon={faHotel}
//                               style={{ color: "#000000" }}
//                             />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[0]} Hotels
//                             </span>
//                           </div>

//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon icon={faCar} />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[1]} Transfers
//                             </span>
//                           </div>

//                           <div
//                             className="col-4 d-flex flex-column align-items-center"
//                             style={{ opacity: "0.5" }}
//                           >
//                             <FontAwesomeIcon icon={faPersonHiking} />
//                             <span
//                               style={{ marginTop: "0.5rem", fontSize: "12px" }}
//                             >
//                               {destination.Activities[2]} Activities
//                             </span>
//                           </div>
//                         </div>
//                         <div
//                           className="mt-2"
//                           style={{
//                             textAlign: "left",
//                             opacity: "0.5",
//                             fontSize: "13px",
//                           }}
//                         >
//                           <span
//                             dangerouslySetInnerHTML={{
//                               __html: destination.Details,
//                             }}
//                           ></span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };
// export default PackageDestination;







import React, { useState, useEffect, useRef } from "react";
import Nav from "./BlueNav";
import { Navigate, useParams } from "react-router-dom";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faStar,
  faHotel,
  faCar,
  faPersonHiking,
  faTag,
  faSun,
  faLocation,
  faLocationPin,
  faCalendar,
  faLocationDot,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./PackageDestination.css";

const PackageDestination = () => {
  const { id } = useParams();
  const { slug } = useParams();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentDetail, setCurrentDetail] = useState("overview"); // Default detail to show
  const [destinations, setDestinations] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState("");
  const [activeItem, setActiveItem] = useState(null); // State to track active accordion item
  const [packageId, setPackageId] = useState("");
  const [isOverviewActive, setIsOverviewActive] = useState(true);
  const [widgetData, setWidgetData] = useState(null); // State to hold widget data
  const [showWidget, setShowWidget] = useState(false); // Default to false

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const whereToGoRef = useRef(null);
  const countryCodeRef = useRef(null);
  const phoneNoRef = useRef(null);
  const messageRef = useRef(null);
  const getQuoteButtonRef = useRef(null);
   

  console.log("sluggf",slug);

  // const fetchPackageData = async () => {
  //   setLoading(true);
  //   try {
  //     const slug = sessionStorage.getItem("slugg");
  //    // console.log("slud",slug);
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}AllPackages`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const responseData = await response.json();
  //     console.log("responseDAta",responseData);
  //     const packageData = responseData.data.find(
  //       (item) => item.slug === slug
  //     );
  //     console.log("packgData",packageData);

  //     // console.log("response Data-", responseData);
  //     // console.log("packageData - ", packageData);
  //     const abc = packageData.category.id;
  //     // console.log("idd--", abc);
  //     //   console.log("useparanskg",slug);

  //     setSelectedPackage(packageData);
  //     setPaymentDetails(packageData ? packageData.PaymentDetails : "");
  //     setPackageId(packageData ? packageData.id : ""); // Set the package ID

  //     // Check if "Visit" data contains widget information
  //     if (packageData && packageData.Visit && packageData.Visit.length > 0) {
  //       setWidgetData(packageData.Visit[0]); // Set widget data
  //       setShowWidget(true); // Show the widget
  //     } else {
  //       setShowWidget(false); // Hide the widget if data is not available
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const fetchPackageData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}AllPackages`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      console.log("responseData", responseData);

      const packageData = responseData.data.find(
        (item) => item.slug === slug
      );
      console.log("packageData", packageData);

      if (!packageData) {
        throw new Error("Package not found");
      }

      setSelectedPackage(packageData);
      setPaymentDetails(packageData.PaymentDetails || "");
      setPackageId(packageData.id || "");

      if (packageData.Visit && packageData.Visit.length > 0) {
        setWidgetData(packageData.Visit[0]);
        setShowWidget(true);
      } else {
        setShowWidget(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchPackageData();
    }
  }, [slug]);
  

  // added -->
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Name: nameRef.current.value,
      Email: emailRef.current.value,
      Phone: phoneNoRef.current.value,
      WhereToGo: whereToGoRef.current.value,
      Message: messageRef.current.value,
      PackageId: packageId, // Include the package ID in the form data
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}PackageQoutes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }
      const responseData = await response.json();
      console.log("Form submission successful:", responseData);
      // Reset form fields if needed
      nameRef.current.value = "";
      emailRef.current.value = "";
      phoneNoRef.current.value = "";
      whereToGoRef.current.value = "";
      messageRef.current.value = "";
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  //--->

  // Fetch data from API

  // ------------------->> All  Destinations

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllDestination`
        );
        const data = await response.json();
        console.log("data of AllDEst Neeche wala",data);
        if (data.status === "true") {
          setDestinations(data.data);
        } else {
          console.error("Error fetching data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // ------------------->> All  Destinations
  // Function to chunk destinations into groups of 4
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };
  const handleButtonClick = (detail) => {
    setCurrentDetail(detail);
    setIsOverviewActive(detail === "overview"); // Update isOverviewActive based on the clicked button
    fetchPackageData(detail);
  };

  useEffect(() => {
    fetchPackageData(currentDetail);
  }, [id, currentDetail]);

  const handleGetQuoteClick = () => {
    nameRef.current.focus();
  };

  const handleAccordionClick = (index) => {
    setActiveItem(activeItem === index ? null : index); // Toggle active item
  };
  

  useEffect(() => {
    // Load the widget script dynamically here based on widgetData
    if (showWidget && widgetData) {
      const script = document.createElement("script");
      script.src = widgetData.scriptUrl; // Assuming widgetData has a scriptUrl field
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showWidget, widgetData]);


  const handleBookOnline = () => {
    sessionStorage.setItem("slugg", slug);
     const categoryId = selectedPackage ? selectedPackage.category.id : null;
     console.log("IDDD-",categoryId);
    if (categoryId === 3) {
      window.location.href = "/BookOnline";
    } else {
      window.location.href = "/BookOnlineCarHotel";
    }
  };


  return (
    <>
      <div className="container p-0">
        <Nav />
        <h3 className="text-center mt-5 pt-0 pt-md-5">
          <b>{selectedPackage && selectedPackage.name}</b>
        </h3>

        <div className="row mt-5 mx-3">
          <div className="col-lg-8">
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {selectedPackage && (
              <>
                <div
                  className="custom-carousel-container"
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <div
                    id="carouselExampleInterval"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {selectedPackage.image.map((img, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                          data-interval="10000"
                        >
                          <img
                            src={`${img}`}
                            className="d-block w-100"
                            style={{ maxHeight: "400px", objectFit: "cover" }}
                            alt={`Slide ${index}`}
                          />
                        </div>
                      ))}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href="#carouselExampleInterval"
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#carouselExampleInterval"
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>

                <div className="d-flex gap-4 flex-wrap mt-5 mb-3">
                  <button
                    className={`btn custom-btn ${
                      currentDetail === "overview" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("overview")}
                    style={{
                      backgroundColor:
                        currentDetail === "overview" ? "#03045E" : "",
                      color: currentDetail === "overview" ? "#FFFFFF" : "",
                    }}
                  >
                    Overview
                  </button>
                  {selectedPackage.category.heading === "Hotel Bookings" ? (
                    <button
                      className={`btn custom-btn ${
                        currentDetail === "hotel_highlight" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("hotel_highlight")}
                      style={{
                        backgroundColor:
                          currentDetail === "hotel_highlight" ? "#03045E" : "",
                        color:
                          currentDetail === "hotel_highlight" ? "#FFFFFF" : "",
                      }}
                    >
                      Hotel Highlight
                    </button>
                  ) : (
                    <button
                      className={`btn custom-btn ${
                        currentDetail === "trip_highlight" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("trip_highlight")}
                      style={{
                        backgroundColor:
                          currentDetail === "trip_highlight" ? "#03045E" : "",
                        color:
                          currentDetail === "trip_highlight" ? "#FFFFFF" : "",
                      }}
                    >
                      Trip Highlight
                    </button>
                  )}

                  {selectedPackage.category.heading !== "Hotel Bookings" && (
                    <button
                      className={`btn custom-btn ${
                        currentDetail === "itinerary_details" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("itinerary_details")}
                      style={{
                        backgroundColor:
                          currentDetail === "itinerary_details"
                            ? "#03045E"
                            : "",
                        color:
                          currentDetail === "itinerary_details"
                            ? "#FFFFFF"
                            : "",
                      }}
                    >
                      Itinerary Detail
                    </button>
                  )}

                  <button
                    ref={getQuoteButtonRef}
                    className={`btn custom-btn ${
                      currentDetail === "get_quote" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("get_quote")}
                    style={{
                      backgroundColor:
                        currentDetail === "get_quote" ? "#03045E" : "",
                      color: currentDetail === "get_quote" ? "#FFFFFF" : "",
                    }}
                  >
                    Get Quote
                  </button>
                </div>

                <div
                  className="align-items-center p-2 mb-2"
                  style={{
                    borderStyle: "dotted",
                    borderRadius: "15px",
                    borderColor: "#CAF0F8",
                  }}
                >
                  <div className="d-inline-flex align-items-center p-2">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ marginRight: "8px" }}
                    />
                    <span style={{ color: "#03045E" }}>
                      <h5 className="mb-0">
                        <b>Destination Covered :</b>
                      </h5>
                    </span>
                  </div>
                  <div
                    className="align-items-center"
                    style={{ color: "#666666", paddingLeft: "30px" }}
                  >
                    <b>{selectedPackage && selectedPackage.location}</b>
                  </div>
                  <div className="ms-2">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faCalendar} />{" "}
                      <span className="ms-2" style={{ color: "black" }}>
                        <h5 style={{ color: "blue", marginTop: "10px" }}>
                          <b style={{ color: "#03045E" }}>
                            Available Tour Dates
                          </b>
                        </h5>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap">
                      {selectedPackage && selectedPackage.category.id === 3 ? (
                        selectedPackage.TourDate.map((date, index) => (
                          <p
                            key={index}
                            style={{ color: "#666666", paddingLeft: "20px" }}
                          >
                            <b>{date}</b>
                          </p>
                        ))
                      ) : (
                        <p style={{ color: "#666666", paddingLeft: "20px" }}>
                          <b>
                            You can select your Dates at the time of Booking
                          </b>
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* ------------ Destination Covered end -------*/}

                <div className="details-container">
                  {currentDetail === "overview" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedPackage.overview,
                      }}
                    ></div>
                  )}

                  {/* Trip Highlight */}
                  {currentDetail === "trip_highlight" && (
                    <div>
                      {selectedPackage.trip_highlight.map(
                        (highlight, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center mb-3 p-3"
                            style={{
                              marginTop: "8px",
                              border: "1.35px solid #0000001A",
                              boxShadow: "1px 1px 2px 0px #0000001A",
                              borderRadius: "8px",
                            }}
                          >
                            <div className="me-2">
                              <FontAwesomeIcon icon={faTag} />
                            </div>
                            <div>{highlight}</div>
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {currentDetail === "hotel_highlight" &&
                    selectedPackage &&
                    selectedPackage.trip_highlight && (
                      <div>
                        {selectedPackage.trip_highlight.map(
                          (highlight, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-3 p-3"
                              style={{
                                marginTop: "8px",
                                border: "1.35px solid #0000001A",
                                boxShadow: "1px 1px 2px 0px #0000001A",
                                borderRadius: "8px",
                              }}
                            >
                              <div className="me-2">
                                <FontAwesomeIcon icon={faHotel} />
                              </div>
                              <div>{highlight}</div>
                            </div>
                          )
                        )}
                      </div>
                    )}

                  {/* --------------> */}

                  {currentDetail === "itinerary_details" &&
                    selectedPackage.intinerary_details && (
                      <div id="accordionItinerary" className="accordion">
                        {selectedPackage.intinerary_details.map(
                          (detailsArray, outerIndex) => (
                            <div
                              className="accordion-item mb-3"
                              key={outerIndex}
                              style={{
                                backgroundColor: "#EFF1F6",
                              }}
                            >
                              {detailsArray && detailsArray[0] && (
                                <div key={outerIndex} className="row">
                                  {/* Simple button */}
                                  <div className="col-2">
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        width: "100%",
                                        height: "52px",
                                        color: "#FFFFFF",
                                        border: "none",
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faSun} /> Day{" "}
                                      {outerIndex + 1}
                                    </button>
                                  </div>
                                  {/* Accordion header and collapse */}
                                  <div className="col-10">
                                    <h2
                                      className="accordion-header"
                                      id={`headingItinerary${outerIndex}`}
                                    >
                                      <button
                                        className={`accordion-button ${
                                          activeItem === outerIndex
                                            ? "active"
                                            : ""
                                        }`} // Apply "active" class when item is active
                                        type="button"
                                        onClick={() =>
                                          handleAccordionClick(outerIndex)
                                        } // Handle click event
                                        aria-expanded={
                                          activeItem === outerIndex
                                            ? "true"
                                            : "false"
                                        }
                                        aria-controls={`collapseItinerary${outerIndex}`}
                                      >
                                        {detailsArray[0]}
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapseItinerary${outerIndex}`}
                                      className={`accordion-collapse collapse ${
                                        activeItem === outerIndex ? "show" : ""
                                      }`} // Apply "show" class when item is active
                                      aria-labelledby={`headingItinerary${outerIndex}`}
                                      data-bs-parent="#accordionItinerary"
                                    >
                                      <div className="accordion-body">
                                        <p>{detailsArray[1]}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}

                  {/* ----------------> */}
                </div>
              </>
            )}
          </div>

          <div className="col-lg-4 ps-0 ps-md-4">
            {/* PaymentDetails Card */}
            <div
              className="card"
              style={{
                boxShadow:
                  "-1.4294641017913818px 1.4294641017913818px 14.29464054107666px 7.14732027053833px #00000014",
                border: "1px solid #00000008",
                borderStyle: "none",
              }}
            >
              <div className="card-body">
                <div className="d-flex gap-2 align-items-center">
                  <span className="card-title text-primary h4 fw-bold text-left w-75">
                    {selectedPackage && selectedPackage.name}{" "}
                  </span>

                  {selectedPackage && selectedPackage.category_id !== 1 && (
                    <span
                      className="text-white p-2 rounded"
                      style={{ fontSize: "15px", background: "#03045E" }}
                    >
                      <FontAwesomeIcon icon={faSun} />{" "}
                      {selectedPackage && selectedPackage.days} Days Tour
                    </span>
                  )}
                </div>
                <hr />
                <div
                  className="card-text"
                  dangerouslySetInnerHTML={{ __html: paymentDetails }}
                ></div>

                <h4 style={{ color: "#03045E" }}>
                  Rs.{" "}
                  <b>
                    {selectedPackage &&
                      (selectedPackage.category_id === 1
                        ? selectedPackage.price[0]
                        : selectedPackage.price)}
                    /-
                  </b>{" "}
                  {/* <small>
    {selectedPackage && selectedPackage.category_id === 1
      ? "Per Night "
      : "Per Person"}
  </small> */}
                  <small>
                    {selectedPackage && selectedPackage.category_id === 1 ? (
                      <>
                        {" "}
                        Night{" "}
                        <span style={{ fontSize: "0.8em" }}>
                          (Double Sharing)
                        </span>{" "}
                        <br></br>
                        <small style={{ fontSize: "0.7em", color: "red" }}>
                          (*food Included*)
                        </small>
                      </>
                    ) : (
                      "Per Person"
                    )}
                  </small>
                </h4>
              </div>
            </div>
            {/* Buttonn Book Online */}

            <div className="mt-4" style={{ borderRadius: "57.03px" }}>
              <Link
                to="#"
                className="btn w-100 btn-primary"
                style={{ borderRadius: "50px" }}
                onClick={handleBookOnline}
              >
                Book Online
              </Link>
            </div>

            {/* Share This Trip */}

            <div className="mt-5 text-center" style={{ color: "#03045E" }}>
              <h5>Share This Trip</h5>
              <div>
                <a
                  href="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="me-2 h4" icon={faFacebook} />
                </a>

                <a
                  href="https://www.instagram.com/travellers_choice_ladakh/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="me-2 h4" icon={faInstagram} />
                </a>

                <a
                  href="https://www.linkedin.com/in/your-linkedin-url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="me-2 h4" icon={faLinkedin} />
                </a>
              </div>
            </div>

            {/* Enquiry */}
            <div
              className="card mt-5"
              style={{
                boxShadow:
                  "-1.3534338474273682px 1.3534338474273682px 13.534337997436523px 6.767168998718262px rgba(219, 226, 247, 0.55)",
                borderRadius: "20px",
                color: "#03045E",
                borderStyle: "none",
              }}
            >
              <div className="card-body text-center">
                <h4 className="card-title">
                  <b>Enquiry</b>
                </h4>
                <h6 className="card-subtitle mb-2 ">
                  Want to know more contact us
                </h6>
                <h5>
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  <a
                    style={{ color: "#03045E", textDecoration: "none" }}
                    href="tel:+91 97977 16377"
                  >
                    +91 97977 16377
                  </a>
                </h5>
              </div>
            </div>

            {/* Form */}
            <div
              className="card mt-3"
              style={{
                backgroundColor: "#F6F7FC",
                boxShadow:
                  "-1.3534338474273682px 1.3534338474273682px 13.534337997436523px 6.767168998718262px rgba(219, 226, 247, 0.55)",
                borderRadius: "20px",
                padding: "20px",
                borderStyle: "none",
              }}
            >
              <div className="card-body text-center">
                <p>
                  <span style={{ color: "red" }}>*</span>Leave a message and
                  We'll get back to you
                </p>
                <form>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Name"
                      ref={nameRef}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      ref={emailRef}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Where to go?"
                      ref={whereToGoRef}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country Code"
                      ref={countryCodeRef}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone No"
                      ref={phoneNoRef}
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Write a message"
                      ref={messageRef}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                  >
                    <b>Submit</b>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Adding Widgets */}

        <div>
          {showWidget && widgetData && (
            <div>
              <h3 className="my-4" style={{ color: "#03045E" }}>
                <b>Explore More</b>
              </h3>
              {/* Render the widget container based on widgetData */}
              <div dangerouslySetInnerHTML={{ __html: widgetData.html }} />
            </div>
          )}
        </div>
        {/* Ladakh Tour Package */}

        <div className="col-12">
          <h3 className="mt-5 mb-3">Trending Ladakh Tour Packages</h3>
          <div>
            {/* Render cards in rows */}
            {chunkArray(destinations, 4).map((row, rowIndex) => (
              <div key={rowIndex} className="row mt-2">
                {row.map((destination) => (
                  <div
                    key={destination.id}
                    className="col-lg-3 col-md-6 pe-4 mb-4"
                  >
                    <div
                      className="card border-0 rounded"
                      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
                    >
                      <img
                        src={destination.Image}
                        className="card-img-top rounded"
                        style={{ height: "250px", objectFit: "cover" }}
                        alt={destination.Name}
                      />
                      <div className="card-body">
                        <h6
                          className="card-title d-flex align-items-center justify-content-between"
                          style={{ fontSize: "18px" }}
                        >
                          {destination.Name}{" "}
                          <span>
                            <FontAwesomeIcon style={{color:"#ffd049"}} icon={faStar} />
                            <span
                              style={{ fontSize: "14px", marginLeft: "5px" }}
                            >
                              {destination.Rating}<span style={{ fontSize: "14px", marginLeft: "5px" }}>
                    4.9
                  </span>
                            </span>
                          </span>
                        </h6>
                        <p style={{ opacity: "0.5", fontSize: "14px" }}>
                          {destination.Day} Days & {destination.Night} Nights
                        </p>
                        <div className="row">
                          <div
                            className="col-4 d-flex flex-column align-items-center"
                            style={{ opacity: "0.5" }}
                          >
                            <FontAwesomeIcon
                              icon={faHotel}
                              style={{ color: "#000000" }}
                            />
                            <span
                              style={{ marginTop: "0.5rem", fontSize: "12px" }}
                            >
                              {destination.Activities[0]} Hotels
                            </span>
                          </div>

                          <div
                            className="col-4 d-flex flex-column align-items-center"
                            style={{ opacity: "0.5" }}
                          >
                            <FontAwesomeIcon icon={faCar} />
                            <span
                              style={{ marginTop: "0.5rem", fontSize: "12px" }}
                            >
                              {destination.Activities[1]} Transfers
                            </span>
                          </div>

                          <div
                            className="col-4 d-flex flex-column align-items-center"
                            style={{ opacity: "0.5" }}
                          >
                            <FontAwesomeIcon icon={faPersonHiking} />
                            <span
                              style={{ marginTop: "0.5rem", fontSize: "12px" }}
                            >
                              {destination.Activities[2]} Activities
                            </span>
                          </div>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            textAlign: "left",
                            opacity: "0.5",
                            fontSize: "13px",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: destination.Details,
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PackageDestination;
