

import React, { useState, useEffect, useRef } from "react";
import LeftImage from "../../assets/Images/a1a1.png";
import { useNavigate } from "react-router-dom";
import wlogo from "../../assets/Images/White_TCL_logo.png";

function Otp() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(120);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 3 && value !== "") {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ otp: otpCode, token: token }),
        }
      );

      console.log("Response status:", response.status);
      const responseBody = await response.json();
      console.log("Response body:", responseBody);

      if (responseBody.status === "true") {
        navigate("/LogIn");
      } else {
        console.error("OTP validation failed:", responseBody.message);
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
        <div className="col-lg-5 p-0 d-none d-lg-block">
        <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${LeftImage})`,
              objectFit:"cover",
               backgroundSize:"100% 100%"
            }}
          >
            <div
                className="ms-3"
                style={{ cursor: "pointer", color: "#FFFFFF" }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img src={wlogo} className="d-none d-md-block" width="185" alt="" />
              </div>
            <div className="h-75 d-flex justify-content-center align-items-center">
              <h4 style={{ color: "#FFFFFF" }}><b>LADAKH</b></h4>
            </div>
          </div>
          </div>
          <div
            className="col-lg-7 "
            style={{ backgroundColor: "#03045E", height: "100vh" }}
          >
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2 custom-card"
                style={{ maxheight: "550px", borderRadius: "20px" }}
              >
                <div className="card-body p-5 py-3">
                  <h2 className="card-title text-center pt-3">OTP Verification</h2>
                  <p className="text-muted text-center pb-3" style={{ fontSize: "medium" }}>
                    Please Verify Your OTP Send On Your Device
                  </p>
                  <form onSubmit={handleSubmit} style={{ padding: "0 50px" }}>
                    <div className="d-flex justify-content-evenly my-3">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          value={digit}
                          maxLength="1"
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          className="form-control"
                          style={{
                            width: "calc(14% - 10px)",
                            textAlign: "center",
                            marginRight: "10px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          }}
                          ref={(input) => (inputRefs.current[index] = input)}
                          required
                        />
                      ))}
                    </div>
                    <p className="text-center mb-3">
                      {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
                      {timer % 60}
                    </p>
                    <p
                      className="text-center mb-3"
                      style={{ color: "#03045E", cursor: "pointer" }}
                    >
                      Didn't Receive Code? <b>Resend</b>
                    </p>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn mt-4"
                        style={{
                          width: "200px",
                          borderRadius: "20px",
                          backgroundColor: "#03045E",
                          color: "white",
                          fontSize: "1.2rem",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                 
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}

export default Otp;
