

// import React, { useState } from "react";
// import Nav from "../HomePage/Nav";
// import help from "../../assets/Images/Helptrip.svg";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faInstagram,
//   faTwitter,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";
// import bg_cont from "../../assets/Images/bg_cont.svg";

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     FirstName: "",
//     LastName: "",
//     Email: "",
//     PhoneNumber: "",
//     Message: "",
//     Subject: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

 

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log("formdata--",formData);

//     try {
//       const response = await fetch(
//         "https://api.travellerchoiceladakh.com/api/ContactUs",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       const data = await response.json();
//       console.log("API Response:", data);

//       if (response.ok) {
//         toast.success("Message sent successfully!", {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });
//       } else {
//         throw new Error(data.message || "Error sending message.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(error.message || "Error sending message.", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//     }

//     // Clear the form after submission
//     setFormData({
//       FirstName: "",
//       LastName: "",
//       Email: "",
//       PhoneNumber: "",
//       Message: "",
//       Subject: "",
//     });
//   };

//   return (
//     <>
//       <div className="container-fluid p-0">
//         <Nav />
//         <div
//           className="position-relative d-flex justify-content-center align-items-center"
//           style={{
//             backgroundImage: `url(${help})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//             minHeight: "100vh",
//           }}
//         >
          
//           <div className="card my-5">
//             <div className="card-body">
//               <div className="row">
//                 <div
//                   className="col-lg-6 p-5 rounded"
//                   style={{
//                     backgroundImage: `url(${bg_cont})`,
//                     backgroundSize: "cover",
//                     backgroundSize: "101%",
//                   }}
//                 >
//                   <h3 className="text-white mb-0">Contact Information</h3>
//                   <p className="text-white">
//                     Any question or remarks? Just write us a message!
//                   </p>
//                   <div className="mt-5 pt-5">
//                     {/* Contact information */}
//                     <div>
//                       <div>
//                         <a
//                           href="tel:6302099078"
//                           className="text-white text-decoration-none"
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="18"
//                             height="18"
//                             fill="currentColor"
//                             className="me-5 bi bi-telephone-outbound-fill"
//                             viewBox="0 0 16 16"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"
//                             />
//                           </svg>{" "}
//                           6302099078{" "}
//                         </a>
//                       </div>
//                       <div className="mt-3">
//                         <a
//                           href="mailto:demo@gmail.com"
//                           className="text-white text-decoration-none"
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="20"
//                             height="20"
//                             fill="currentColor"
//                             className="bi bi-envelope-at-fill me-5"
//                             viewBox="0 0 16 16"
//                           >
//                             <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
//                             <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
//                           </svg>{" "}
//                           demo@gmail.com{" "}
//                         </a>
//                       </div>
//                       <div className="text-white mt-3 d-flex">
//                         <svg
//                           xmlns="http://www.w3.org/2000/svg"
//                           width="28"
//                           height="28"
//                           fill="currentColor"
//                           className="bi bi-geo-alt-fill me-5"
//                           viewBox="0 0 16 16"
//                         >
//                           <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
//                         </svg>
//                         <div>
//                           132 Dartmouth Street Boston, Massachusetts 02156
//                           United States
//                         </div>
//                       </div>
//                     </div>
//                     <h4 className="mt-5 pt-5">
//                       <span
//                         className="bg-white p-2"
//                         style={{ borderRadius: "50%" }}
//                       >
//                         <FontAwesomeIcon
//                           className="text-primary"
//                           icon={faFacebook}
//                         />
//                       </span>
//                       <span
//                         className="bg-white p-2 ms-3"
//                         style={{ borderRadius: "50%" }}
//                       >
//                         <FontAwesomeIcon
//                           className="text-primary"
//                           icon={faInstagram}
//                         />
//                       </span>
//                       <span
//                         className="bg-white p-2 ms-3"
//                         style={{ borderRadius: "50%" }}
//                       >
//                         <FontAwesomeIcon
//                           className="text-primary"
//                           icon={faTwitter}
//                         />
//                       </span>
//                       <span
//                         className="bg-white p-2 ms-3"
//                         style={{ borderRadius: "50%" }}
//                       >
//                         <FontAwesomeIcon
//                           className="text-primary"
//                           icon={faYoutube}
//                         />
//                       </span>
//                     </h4>
//                   </div>
//                 </div>
//                 <div className="col-lg-6 ps-4 mt-4">
//                   <form className="w-100" onSubmit={handleSubmit}>
//                     <div className="mb-4 d-flex gap-2">
//                       <input
//                         type="text"
//                         placeholder="First Name"
//                         className="input w-50"
//                         name="FirstName"
//                         value={formData.FirstName}
//                         onChange={handleChange}
//                         required
//                       />
//                       <input
//                         type="text"
//                         placeholder="Last Name"
//                         className="input w-50"
//                         name="LastName"
//                         value={formData.LastName}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     <div className="mb-4 d-flex gap-2">
//                       <input
//                         type="email"
//                         placeholder="Email"
//                         className="input w-50"
//                         name="Email"
//                         value={formData.Email}
//                         onChange={handleChange}
//                         required
//                       />
//                       <input
//                         type="number"
//                         placeholder="Phone Number"
//                         className="input w-50"
//                         name="PhoneNumber"
//                         value={formData.PhoneNumber}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>
//                     <div className="mb-4">
//                       <p className="mb-0 fw-semibold mb-2">Select Subject?</p>
//                       <div className="form-check form-check-inline">
//                         <input
//                           className="form-check-input mt-1"
//                           type="radio"
//                           name="Subject"
//                           id="inlineRadio1"
//                           value="General Inquiry"
//                           onChange={handleChange}
//                         />
//                         <label
//                           className="form-check-label fw-normal"
//                           htmlFor="inlineRadio1"
//                         >
//                           General Inquiry
//                         </label>
//                       </div>
//                       <div className="form-check form-check-inline">
//                         <input
//                           className="form-check-input mt-1"
//                           type="radio"
//                           name="Subject"
//                           id="inlineRadio2"
//                           value="Trip Inquiry"
//                           onChange={handleChange}
//                         />
//                         <label
//                           className="form-check-label fw-normal"
//                           htmlFor="inlineRadio2"
//                         >
//                           Trip Inquiry
//                         </label>
//                       </div>
//                       <div className="form-check form-check-inline">
//                         <input
//                           className="form-check-input mt-1"
//                           type="radio"
//                           name="Subject"
//                           id="inlineRadio3"
//                           value="Package Inquiry"
//                           onChange={handleChange}
//                         />
//                         <label
//                           className="form-check-label fw-normal"
//                           htmlFor="inlineRadio3"
//                         >
//                           Package Inquiry
//                         </label>
//                       </div>
//                     </div>

//                     <div className="mb-4">
//                       <input
//                         type="text"
//                         placeholder="Message"
//                         className="input w-100"
//                         name="Message"
//                         value={formData.Message}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>
//                     <div className="mb-4 text-end">
//                       <button type="submit" className="btn btn-primary p-3">
//                         Send Message
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default React.memo(ContactUs);
// <ToastContainer />







import React, { useState } from "react";
import Nav from "../HomePage/Nav";
import help from "../../assets/Images/Helptrip.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import bg_cont from "../../assets/Images/bg_cont.svg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    Message: "",
    Subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formDaata-",formData);
    // Validate PhoneNumber length
    if (formData.PhoneNumber.length !== 10) {
      toast.error("Phone number must be 10 digits.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Validate other required fields
    if (
      !formData.FirstName ||
      !formData.LastName ||
      !formData.Email ||
      !formData.Message ||
      !formData.Subject
    ) {
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await fetch(
        "https://api.travellerchoiceladakh.com/api/ContactUs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();

      if (response.ok) {
        toast.success("Message sent successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("res-",response)
      } else {
        throw new Error(data.message || "Error sending message.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || "Error sending message.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // Clear the form after submission
    setFormData({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      Message: "",
      Subject: "",
    });
  };
  return (
    <>
      <div className="container-fluid p-0">
        <Nav />
        <div
          className="position-relative d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${help})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
          }}
        >
          
          <div className="card my-5">
            <div className="card-body">
              <div className="row">
                <div
                  className="col-lg-6 p-5 rounded"
                  style={{
                    backgroundImage: `url(${bg_cont})`,
                    backgroundSize: "cover",
                    backgroundSize: "101%",
                  }}
                >
                  <h3 className="text-white mb-0">Contact Information</h3>
                  <p className="text-white">
                    Any question or remarks? Just write us a message!
                  </p>
                  <div className="mt-5 pt-5">
                    {/* Contact information */}
                    <div>
                      <div>
                        <a
                          href="tel:+917006363828"
                          className="text-white text-decoration-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="me-5 bi bi-telephone-outbound-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"
                            />
                          </svg>{" "}
                          7006363828 /  <a
                          href="tel:+919149516495"
                          className="text-white text-decoration-none"
                        >9149516495</a>{" "}
                        </a>
                      </div>
                      <div className="mt-3">
                        <a
                          href="mailto:travellerschoice27@gmail.com"
                          className="text-white text-decoration-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-envelope-at-fill me-5"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                          </svg>{" "}
                          travellerschoice27@gmail.com{" "}
                        </a>
                      </div>
                      <div className="text-white mt-3 d-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-5"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                        <div>
                        Chhutay rantak , sankar road , opp. Hotel antelope , Leh-Ladakh, 194101
                        </div>
                      </div>
                    </div>
                    <h4 className="mt-5 pt-5">

                      {/* <span
                        className="bg-white p-2"
                        style={{ borderRadius: "50%" }}
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faFacebook}
                        />
                      </span>
                      <span
                        className="bg-white p-2 ms-3"
                        style={{ borderRadius: "50%" }}
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faInstagram}
                        />
                      </span> */}
                      {/* <span
                        className="bg-white p-2 ms-3"
                        style={{ borderRadius: "50%" }}
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faTwitter}
                        />
                      </span> */}
                      {/* <span
                        className="bg-white p-2 ms-3"
                        style={{ borderRadius: "50%" }}
                      >
                        <FontAwesomeIcon
                          className="text-primary"
                          icon={faYoutube}
                        />
                      </span> */}
                      <span className="bg-white p-2" style={{ borderRadius: "50%" }}>
  <a href="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="text-primary" icon={faFacebook} />
  </a>
</span>
<span className="bg-white p-2 ms-3" style={{ borderRadius: "50%" }}>
  <a href="https://www.instagram.com/travellers_choice_ladakh/" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="text-primary" icon={faInstagram} />
  </a>
</span>
{/* <span className="bg-white p-2 ms-3" style={{ borderRadius: "50%" }}>
  <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="text-primary" icon={faTwitter} />
  </a>
</span> */}
<span className="bg-white p-2 ms-3" style={{ borderRadius: "50%" }}>
  <a href="https://youtube.com/@TRAVELLERSCHOICE-ll2kl?si=EX6n-jYw7HA-1oK3" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon className="text-primary" icon={faYoutube} />
  </a>
</span>



                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 ps-4 mt-4">
                  <form className="w-100" onSubmit={handleSubmit}>
                    <div className="mb-4 d-flex gap-2">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="input w-50"
                        name="FirstName"
                        value={formData.FirstName}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="input w-50"
                        name="LastName"
                        value={formData.LastName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="mb-4 d-flex gap-2">
                      <input
                        type="email"
                        placeholder="Email"
                        className="input w-50"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        required
                      />
                      <input
                        type="number"
                        placeholder="Phone Number"
                        className="input w-50"
                        name="PhoneNumber"
                        value={formData.PhoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <p className="mb-0 fw-semibold mb-2">Select Subject?</p>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          name="Subject"
                          id="inlineRadio1"
                          value="General Inquiry"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="inlineRadio1"
                        >
                          General Inquiry
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          name="Subject"
                          id="inlineRadio2"
                          value="Trip Inquiry"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="inlineRadio2"
                        >
                          Trip Inquiry
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          name="Subject"
                          id="inlineRadio3"
                          value="Package Inquiry"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="inlineRadio3"
                        >
                          Package Inquiry
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <input
                        type="text"
                        placeholder="Message"
                        className="input w-100"
                        name="Message"
                        value={formData.Message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 text-end">
                      <button type="submit" className="btn btn-primary p-3">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ContactUs);
<ToastContainer />



