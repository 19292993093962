import React, { useEffect, useState } from "react";
import BlueNav from "../HomePage/BlueNav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookOnlineCarHotel = (prop) => {
  const [packageName, setPackageName] = useState("");
  const [people, setPeople] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      date: "",
      time: "",
      age: "",
    },
  ]);
  const [packageDetails, setPackageDetails] = useState({
    name: "",
    location: "",
    days: "",
    price: "",
    image: "",
    TourDate: [],
    TourTime: [],
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status

  const [selectedpackageid, setselectedpackageid] = useState("");

  //  Date and Time States
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(""); // Define selectedTime state
  const [selectedDate, setSelectedDate] = useState(""); // Define selectedDate state
  const [dateDifference, setDateDifference] = useState(0);
  const [includeFood, setIncludeFood] = useState(null);

  useEffect(() => {
    if (selectedFromDate && selectedToDate) {
      const fromDateTime = selectedFromDate.getTime(); // Get the time in milliseconds
      const toDateTime = selectedToDate.getTime(); // Get the time in milliseconds
      const differenceInMs = Math.abs(toDateTime - fromDateTime); // Calculate the absolute difference in milliseconds
      const differenceInDays = Math.ceil(
        differenceInMs / (1000 * 60 * 60 * 24)
      ); // Convert milliseconds to days and round up
      setDateDifference(differenceInDays); // Update the date difference state
      calculateTotalPrice(differenceInDays); // Update total price based on date difference
    } else {
      setDateDifference(0); // If either date is not selected, set the difference to 0
      setTotalPrice(0); // Set total price to 0 when dates are not selected
    }
  }, [selectedFromDate, selectedToDate, includeFood]);

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
    const updatedRequestData = { ...prepareFormData(), ToDate: date };
    console.log("Updated requestData:", updatedRequestData);
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   // Check if user is logged in
    //   const loggedIn = sessionStorage.getItem("isLoggedIn");
    //   setIsLoggedIn(loggedIn);

    //   try {
    //     const response = await fetch(
    //       `${process.env.REACT_APP_API_URL}AllPackages`
    //     );
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch data");
    //     }
    //     const data = await response.json();

    //     // Find the package with the matching ID and extract the details
    //     const selectedPackage = data.data.find(
    //       //(demo) => demo.id === parseInt(sessionStorage.getItem("selectedId"))
    //       (demo) => demo.slug === sessionStorage.getItem("slugg")
    //     );

    //     // packageId Set
    //     console.log("selpck",selectedPackage);
    //     console.log("selectedpackg id--", selectedPackage.category.id);
    //     const packageId = selectedPackage.category.id;
    //     console.log("packID",packageId);
    //     setselectedpackageid(packageId);

    //     if (selectedPackage) {
    //       const { name, location, days, price, image, TourDate, TourTime } =
    //         selectedPackage;
    //       setPackageDetails({
    //         name,
    //         location,
    //         days,
    //         price,
    //         image,
    //         TourDate,
    //         TourTime,
    //       });
    //       setPackageName(name);

    //       // Set initial totalPrice and adultCount based on package details
    //       setTotalPrice(parseInt(price));
    //       setAdultCount(1); // Default adult count
    //     } else {
    //       throw new Error("Package not found");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching package details:", error.message);
    //   }
    // };

    const fetchData = async () => {
      const loggedIn = sessionStorage.getItem("isLoggedIn");
      setIsLoggedIn(loggedIn);
    
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}AllPackages`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
    
        const selectedPackage = data.data.find(
          (demo) => demo.slug === sessionStorage.getItem("slugg")
        );
        
        // console.log("sselectedPacksdfge",selectedPackage);

        if (selectedPackage) {
          const { name, location, days, price, image, TourDate, TourTime } = selectedPackage;
          const packageId = selectedPackage.category.id;
          setselectedpackageid(packageId);
    
          setPackageDetails({
            name,
            location,
            days,
            price,
            image,
            TourDate,
            TourTime,
          });
          setPackageName(name);
          setTotalPrice(parseInt(price));
          setAdultCount(1);
        } else {
          throw new Error("Package not found");
        }
      } catch (error) {
        console.error("Error fetching package details:", error.message);
      }
    };
    

    fetchData();
  }, []);

  const handleAddPerson = () => {
    const firstPerson = people[0]; // Get the details of the first person
    const newPerson = {
      ...firstPerson,
      firstName: "", // Optionally reset the firstName field of the new person
      lastName: "", // Optionally reset the lastName field of the new person
      email: "",
      phone: "",
      date: "", // Reset the date field of the new person
      time: "", // Reset the time field of the new person
      age: "",
    };
    const updatedPeople = [...people, newPerson]; // Add the new person to the people array
    setPeople(updatedPeople);
  };

  const handleDeletePerson = (index) => {
    const updatedPeople = people.filter((_, i) => i !== index);
    setPeople(updatedPeople);
  };
  
  // console.log("selectedSessinonIdff",sessionStorage.getItem("selectedId"));

  useEffect(() => {
    calculateTotalPrice(); // Calculate total price whenever people, date difference, or includeFood changes
  }, [people, dateDifference, includeFood]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPeople = [...people];

    if (name === "date") {
      updatedPeople[index][name] = value;
      setSelectedDate(value); // Update selectedDate state
    } else if (name === "time") {
      updatedPeople[index][name] = value;
      setSelectedTime(value); // Update selectedTime state
    } else if (name === "age") {
      updatedPeople[index][name] = Math.max(0, parseInt(value)); // Ensure age is not below 0
    } else {
      updatedPeople[index][name] = value;
      updatedPeople[index].date = selectedDate; // Update date based on selectedDate
    }

    setPeople(updatedPeople);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted People:", people);
    console.log("Token:", sessionStorage.getItem("token"));
    console.log("Total Price:", totalPrice);

    const requestData = prepareFormData();
    console.log("requseDATA,", requestData);
    try {
      const response = await fetch(
        "https://api.travellerchoiceladakh.com/api/PhonePeData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            cors: "no-cors",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
      const responseData = await response.json();
      console.log("responnse-->", responseData);
      const { data } = responseData;
      const redirectUrl = `https://api.travellerchoiceladakh.com/api/phonePe/${data}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Error submitting data:", error.message);
      alert("Error submitting data: " + error.message);
    }
  };
 
   //console.log("sessionIdComing-",sessionStorage.getItem("selectedId"));

  const prepareFormData = () => {
    const travellerDetail = {};
    people.forEach((person, index) => {
      travellerDetail[index.toString()] = [
        person.firstName,
        person.lastName,
        person.email,
        person.phone,
        person.age,
      ];
    });

    const requestData = {
      TravellerDetail: travellerDetail,
      //PackageId: Number(sessionStorage.getItem("selectedId")), // Convert to number
       PackageId: sessionStorage.getItem("selectedId"),
      UserId: sessionStorage.getItem("token"),
      Amount: totalPrice,
      BookingId: generateRandomNumber(),
      BikeDate: null,
      FromDate: selectedFromDate,
      ToDate: selectedToDate,
      WithFood: includeFood ? 1 : 0,
    };
    return requestData;
  };

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * 100000);
    const randomId = `TCL${randomNumber.toString().padStart(5, "0")}`;
    return randomId;
  };
  const redirectToLogin = () => {
    window.location.href = "/login";
  };
  const calculateTotalPrice = () => {
    // console.log(selectedpackageid, packageDetails);
    if (selectedpackageid !== 1 && packageDetails.category !== "Hotels") {
      const totalPrice = parseInt(packageDetails.price) * people.length;
      setTotalPrice(totalPrice);
    } else {
      let pricePerDay = includeFood
        ? parseInt(packageDetails.price)
        : parseInt(packageDetails.price[1]);
      const totalDays = dateDifference;
      const peopleCount = people.length;
      const totalPrice = totalDays * peopleCount * pricePerDay;
      setTotalPrice(totalPrice);
      setAdultCount(peopleCount); // Update adult count based on people array length
    }
  };

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
    calculateToDate(date); // Calculate and set ToDate
    // Update FromDate in requestData
    const updatedRequestData = { ...prepareFormData(), FromDate: date };
    console.log("Updated requestData:", updatedRequestData);
  };
  const handleIncludeFoodChange = (e) => {
    const { value } = e.target;
    setIncludeFood(value === "yes");
    // Update WithFood in requestData
    const updatedRequestData = {
      ...prepareFormData(),
      WithFood: value === "yes" ? 1 : 0,
    };
    console.log("Updated requestData:", updatedRequestData);
  };

  const calculateToDate = (fromDate) => {
    if (fromDate && packageDetails.days) {
      const toDate = new Date(
        fromDate.getTime() + packageDetails.days * 24 * 60 * 60 * 1000
      );
      setSelectedToDate(toDate);
    }
  };

  return (
    <>
      <BlueNav />
      <div className="container mt-5">
        {isLoggedIn ? (
          <div className="row pt-5">
            <div className="col-lg-7">
              <div>
                <h4>
                  <b>{packageName}</b>
                </h4>
              </div>
              <h4>Contact Details</h4>
              <h6>
                We'll use this information to send you confirmation and updates
                about your booking.
              </h6>
              {people.map((person, index) => (
                <form key={index} onSubmit={handleSubmit}>
                  <div className="row border rounded mt-3 p-3">
                    <div className="mb-3 col-md-6 pe-2">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={person.firstName}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="First Name"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={person.lastName}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="mb-3 col-md-6 pe-2">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={person.email}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Email"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        value={person.phone}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="fw-normal" style={{ color: "#545454" }}>
                        From Date:
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={selectedFromDate}
                        onChange={handleFromDateChange}
                        dateFormat="dd/MM/yyyy"
                        disabled={index !== 0} // Disable if it's not the first person
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="fw-normal" style={{ color: "#545454" }}>
                        To Date:
                      </label>

                      <DatePicker
                        className="form-control"
                        selected={selectedToDate}
                        onChange={handleToDateChange}
                        dateFormat="dd/MM/yyyy"
                        disabled={
                          selectedToDate !== null || // Disable if ToDate is already selected
                          (selectedpackageid !== 1 &&
                            packageDetails.category !== "Hotels") || // Disable for non-Hotels category
                          people.length > 1 // Disable if more than 1 person is added
                        }
                      />
                    </div>

                    <div className="mb-3 col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        name="age"
                        value={person.age}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Age"
                      />
                    </div>
                  </div>

                  {index !== 0 && (
                    <button
                      type="button"
                      className="btn btn-danger mb-3"
                      onClick={() => handleDeletePerson(index)}
                    >
                      Delete
                    </button>
                  )}
                </form>
              ))}

              {selectedpackageid === 1 && (
                <div className="row">
                  <p>Do You want to include Food too?</p>
                  <small style={{ color: "red" }}>
                    *By default food inclusion is not selected*{" "}
                  </small>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="includeFood"
                      id="includeFoodYes"
                      value="yes"
                      onChange={handleIncludeFoodChange} // Add onChange event handler
                      checked={includeFood === true} // Check the radio button based on includeFood state
                    />
                    <label
                      className="form-check-label"
                      htmlFor="includeFoodYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="includeFood"
                      id="includeFoodNo"
                      value="no"
                      onChange={handleIncludeFoodChange} // Add onChange event handler
                      checked={includeFood === false} // Check the radio button based on includeFood state
                    />
                    <label className="form-check-label" htmlFor="includeFoodNo">
                      No
                    </label>
                  </div>
                </div>
              )}
              <div className="text-end mt-4">
                {people.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleAddPerson}
                  >
                    Add More People
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-success ms-2 mb-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <p>Total Days- {dateDifference}</p>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="border rounded p-3 ms-0 ms-lg-4 shadow-sm">
                <h4 className="m-2">Reservation Summary</h4>
                <hr />
                <div className="d-flex">
                  <div>
                    {" "}
                    <img
                      src={packageDetails.image[0]}
                      alt="Package Image"
                      width="200"
                      height="118"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div
                    className="ms-2"
                    style={{ fontSize: "16px", lineHeight: "1.2" }}
                  >
                    <p className="mb-2 fw-semibold">{packageDetails.name}</p>
                    <p className="mb-2">{packageDetails.location}</p>
                    {selectedpackageid === 1 ? (
                      <p>Days- {dateDifference}</p>
                    ) : (
                      <p>
                        <p className="mb-2">Days- {packageDetails.days}</p>{" "}
                      </p>
                    )}
                  </div>
                </div>

                <h5 className="mt-4">
                  Total price = <b>Rs {totalPrice}</b>{" "}
                </h5>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6 mt-5 text-center">
              <h3>Please log in to access this page</h3>
              <button
                className="btn btn-primary mt-3"
                onClick={redirectToLogin} // Call redirectToLogin function on button click
              >
                Log In
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookOnlineCarHotel;
